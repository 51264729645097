import React from "react";
import { Link } from "react-router-dom";
import { currentUserRole, currentInitials } from "_components";

import Avatar from '@material-ui/core/Avatar';
import { deepPurple } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Logo from "assets/img/Logo_whitebg_36.png";

// reactstrap components
import {
  // Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  // NavItem,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

const useStyles = makeStyles((theme) => ({
  loghead: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    purple: {
      // color: theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: deepPurple[500],
    },
    inlineBlock: {
      display: "inline-block",
      padding: "0px",
      width: "auto"
    },
  },
}));

function ScrollTransparentNavbar() {
  const classes = useStyles();
  // const auth = useContext(AuthContext);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [navbarColor, setNavbarColor] = React.useState(
    (document.documentElement.scrollTop > 499 || document.body.scrollTop) > 499
      ? ""
      : " navbar-transparent"
  );
  // const [buyButtonColor, setBuyButtonColor] = React.useState(
  //   (document.documentElement.scrollTop > 499 || document.body.scrollTop) > 499
  //     ? "info"
  //     : "neutral"
  // );
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("");
        // setBuyButtonColor("info");
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor(" navbar-transparent");
        // setBuyButtonColor("neutral");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top" + navbarColor} color="white" expand="lg">
        <Container>
          <div className="navbar-translate">
            <img src={Logo} alt="Village Market" style={{ marginRight: "10px"}} />
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
               Village Market
            </NavbarBrand>
            <UncontrolledTooltip target="navbar-brand">
               For local food and produce  
            </UncontrolledTooltip>
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" navbar>
    
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  id="navbarDropdownMenuLink"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <i
                    aria-hidden={true}
                    className="now-ui-icons files_paper"
                  ></i>
                  <p>User Menu</p>
                </DropdownToggle>


                <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>

                  <DropdownItem to="/" tag={Link}>
                    <i className="now-ui-icons files_paper"></i>
                    Home
                  </DropdownItem>

                  <DropdownItem to="/maps" tag={Link}>
                    <i className="now-ui-icons location_map-big"></i>
                    Map
                  </DropdownItem>

                  <DropdownItem to="/search" tag={Link}>
                    <i className="now-ui-icons ui-1_zoom-bold"></i>
                    Search
                  </DropdownItem>

                  <DropdownItem to="/favorites" tag={Link}>
                    <i className="now-ui-icons shopping_box"></i>
                    Favorites
                  </DropdownItem>

                  {/* <DropdownItem to="/latest" tag={Link}>
                    <i className="now-ui-icons ui-2_settings-90"></i>
                    Latest Stores and Updates
                  </DropdownItem>
                  <DropdownItem to="/sections#testimonials" tag={Link}>
                    <i className="now-ui-icons ui-2_chat-round"></i>
                    Testimonials
                  </DropdownItem> */}

                  <DropdownItem to="/contactus" tag={Link}>
                    <i className="now-ui-icons ui-1_email-85"></i>
                    Contact Us
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>


              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  id="navbarDropdownMenuLink"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <i
                    aria-hidden={true}
                    className="now-ui-icons users_circle-08"
                  ></i>
                  {
                    (currentUserRole() === "vm_seller_role" || currentUserRole() === "vm_buyer_role") 
                    ? <p>Hello {currentInitials()} </p>
                    : <p>Log In / Sign Up</p>
                  }
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>

                  {/* {alert("CURRENT INIT: " + currentInitials() )} */}

                  {  
                  (currentUserRole() === "vm_seller_role" || currentUserRole() === "vm_buyer_role") 
                  ? ( <>
                      <div className={classes.loghead} >

                        <Avatar className={classes.purple}> {currentInitials()} </Avatar>
                        <DropdownItem tag={Link} to="/logout-page" className={classes.inlineBlock}>
                          <i className="now-ui-icons ui-1_lock-circle-open"></i>
                          Logout 
                        </DropdownItem>

                      </div>
                      </>
                    )
                  : ( <>
                    <DropdownItem tag={Link} to="/login-page">
                      <i className="now-ui-icons users_circle-08"></i>
                      Login 
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/createacct">
                      <i className="now-ui-icons objects_key-25"></i>
                      SignUp for Sellers/Buyers
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/forgotpass">
                      <i className="now-ui-icons gestures_tap-01"></i>
                      Reset Password
                    </DropdownItem>
                    </>
                    )
                  }

                  {
                  (currentUserRole() === "vm_seller_role")
                  ?
                    <DropdownItem tag={Link} to="/managestores">
                      <i className="now-ui-icons business_money-coins"></i>
                      Manage your Store(s)
                    </DropdownItem>
                  :
                  ""
                  }

                </DropdownMenu>
              </UncontrolledDropdown>

            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ScrollTransparentNavbar;
