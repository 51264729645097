import React, { useState, useContext } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { MapContext } from "_components";

// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
// import styles from "assets/jss/material-kit-react/views/mapPage.js";
// const useStyles = makeStyles(styles);


export default function MapChooser() {

  const [loc, setLoc] = useState({lat: 14.6337, lng: 121.0559})  
  const [isMarkerShown, setMarkerShown] = useState(false);

  const [mymap, setMymap] = useContext(MapContext);

  const onMapClick = (e) => {
    setMarkerShown(true);
    setLoc({lat: e.latLng.lat(),lng: e.latLng.lng()});
    // setMymap(mymap => ({lat: e.latLng.lat(), lng: e.latLng.lng()} ));
    setMymap(Object.assign(mymap, {lat: e.latLng.lat(), lng: e.latLng.lng()} ))     //  Did it this way because above simple operation did not work ???  
    // console.log(loc);
    // console.log(mymap);
  };

  const MyMapComponent = withScriptjs(
    withGoogleMap(props => (
      <GoogleMap
        defaultZoom={13}
        defaultCenter={{ lat: props.lat, lng: props.lng }}
        onClick={props.onClick}
      >
            { isMarkerShown
                ? <Marker position={{ lat: props.lat, lng: props.lng }} />
                : null
            }
      </GoogleMap>
    ))
  );

  return (
    <div className="container">
      <div className="map">
        <MyMapComponent
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyABOu2v7txJ83jlYv7SutPUO77HHocrZkU&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          lat={loc.lat}
          lng={loc.lng}
          onClick={onMapClick}      // You need this in outer call https://stackoverflow.com/questions/54366513/react-google-maps-is-refreshing-the-map-when-onclick-is-performed
        />
      </div>
    </div>
  );
};
