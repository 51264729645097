/*eslint-disable*/
import React, {useState, useEffect, useRef} from "react";
import ReactPaginate from "react-paginate";
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Subscribe from "components/Subscribe/Subscribe.js";  
import Footer from "components/Footer/Footer.js";
import axios from "axios";
import { apiUrl, siteUrl, jwAToken } from "config.js";

//UI
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Paper from "@material-ui/core/Paper";
import Box from '@material-ui/core/Box';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/mapPage.js";
const useStyles = makeStyles(styles);
import "./styles.css";

const axiosHeader = {
  headers: { Authorization: `Bearer ${jwAToken}` }
};

function Search() {
  const classes = useStyles();
  // focus for inputs
  const [currentPage, setCurrentPage] = useState(0);
  const [storefeed, setStorefeed] = useState([]);
  const searchRef = useRef(null)
  const [searchterm, setSearchterm] = useState();
  const PER_PAGE = 10;
  const offset = currentPage * PER_PAGE;
  const pageCount = Math.ceil(storefeed.length / PER_PAGE);

  const currentPageData = storefeed
    .slice(offset, offset + PER_PAGE)
    .map(({ media_link, subsite_link, store_name, store_brief }) => 
    <div style={{ width: '80%' }}>
      <Box display="flex" flexDirection="row" p={1} m={1} bgcolor="background.paper">
        <Box p={1} bgcolor="grey.300">
          <img src={media_link} />
        </Box>
          <Card>
            <CardContent>
              <CardActionArea onClick={() => openLink(siteUrl + subsite_link)} >
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                  {store_name}
              </Typography>
              </CardActionArea>

              <Typography variant="subtitle2" gutterBottom color="textSecondary" component="p">
                  {store_brief}
              </Typography>                   

          </CardContent>
        </Card>
      </Box>
    </div>  
    );

  const openLink = (url) => {
      window.location.replace(url);
  }
    
  const handleSubmit = e => {
      e.preventDefault();
      // alert(searchRef.current.value);
      setSearchterm(searchRef.current.value);

    };

  function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
    }

  // /////////////////////////////////////
  // data fetching from the API
    useEffect(() => {
      FetchData()
    }, [searchterm]);

    const FetchData = async () => {
      const data = await axios.post(
            `${apiUrl}/rpc/vmsearch`,
            {
              "vterms": searchterm || "none"
            },
            axiosHeader
          );
      console.log(data.data)
      // const storefeed = data.data;
      setStorefeed(data.data);
    }
    // END fetch

  return (
    <>
      <ScrollTransparentNavbar />
      <div className="wrapper">
        {/* HEADER SECTION */}
        <Paper elevation={0} className={classes.paper} />
        <form onSubmit={handleSubmit} className="search">
          <input type="text" ref={searchRef} placeholder="Enter search term(s)..." />
          <input type="submit" name="Submit" />
        </form>  

        {/* MAIN SECTION */}
        <div className="main">
            <div className="App">
                {/* <h1>Search for Stores</h1> */}
                <ReactPaginate
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    pageClassName={"pageli_element"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                />
                {currentPageData}
            </div>
        </div>
        {/* SUBSCRIBE SECTION */}
        <Subscribe />
        <Footer />
      </div>
    </>
  );
};

export default Search;