import React, { createContext, useState } from "react";
// Different take on Context
// https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react

export const CategoryContext = createContext([{}, () => {}]);
// This context provider is passed to any component requiring the context
export const CategoryProvider = (props) => {
  const [cat, setCat] = useState({});
  return (
    <CategoryContext.Provider value = {[cat, setCat]}>
      {props.children}
    </CategoryContext.Provider>
  )
};

export const MapContext = createContext([{}, () => {}]);

export const MapProvider = (props) => {
  const [mymap, setMymap] = useState({});
  return (
    <MapContext.Provider value = {[mymap, setMymap]}>
      {props.children}
    </MapContext.Provider>
  )
};

export const MediaContext = createContext([{}, () => {}]);

export const MediaProvider = (props) => {
  const [media, setMedia] = useState({});
  return (
    <MediaContext.Provider value = {[media, setMedia]}>
      {props.children}
    </MediaContext.Provider>
  )
};