import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LoginForm from 'components/LoginForm/LoginForm';

// UI
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
// import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import image from "assets/img/food1.jpg";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  // const { ...rest } = props;
  return (
    <div>
      <ScrollTransparentNavbar/>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <Card className={classes[cardAnimaton]}>

              <LoginForm
                backgroundColor="white"
                heading="Welcome Back!"
                subheading="Enter your email and password and we'll get you signed in."
              />

              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
