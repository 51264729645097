import React, {Component} from 'react'
import GoogleMapReact from 'google-map-react'
import Marker from './Marker'
 
const getMapOptions = (maps) => {
  return {
    // disableDefaultUI: true,
    mapTypeControl: true,
    streetViewControl: true,
    styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
  };
};

class GoogleMap extends Component {
  static defaultProps = {
    center: {
      lat: 14.622925, 
      lng: 121.067526
    },
    zoom: 15
  };
   
  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '70vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyARZDM3T4lyI2rNc00GtMSDzaDQQJFtINQ' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          options={getMapOptions}
          center= {[this.props.lat, this.props.lng]}
        >
          <Marker
            lat={this.props.lat}
            lng={this.props.lng}
            name={this.props.name}
          />       
        </GoogleMapReact>
      </div>
    );
  }
}
 
export default GoogleMap;