import React, {useEffect} from "react";

// reactstrap components
import {
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselControl
} from "reactstrap";

// core components
const items = [
  {
    src: require("assets/img/food1.jpg"),
    content: (
      <Row>
        <Col className="ml-auto mr-auto" md="8">
          <h1 className="title">Village Market For Consumers.</h1>
          <h4 className="description text-white">
            Find all your local food produce in one site.
            Get updates from your favorites if they've changed information. 
            Deal with sellers directly.
            Get updates on new market sellers in your area.
          </h4>
        </Col>
      </Row>
    ),
    altText: "Slide 1",
    caption: "Slide 1",
  },
  {
    src: require("assets/img/food2.jpg"),
    content: (
      <Row>
        <Col className="ml-auto mr-auto text-center" md="8">
          <h1 className="title">Village Market For Sellers.</h1>
          <h4 className="description text-white">
            Connect to your buyers directly. 
            Connect all your social media links and when you update your site we inform all links as well.
            Store updates are pushed to your followers via email and social media.
          </h4>
        </Col>
      </Row>
    ),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/food3.jpg"),
    content: (
      <Row>
        <Col className="ml-auto mr-auto" md="8">
          <h1 className="title">Village Market For All.</h1>
          <h4 className="description text-white">
            Site that is solely dedicated to food produce.
            Buyers will not get overwelmed with too many sellers and products when they only want local food produce. <br></br>
            Easily search by food categories and location.
          </h4>
        </Col>
      </Row>
    ),
    altText: "",
    caption: "",
  },
];

function EcommerceHeader() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {items.map((item) => {
          return (
            <CarouselItem
              onExiting={onExiting}
              onExited={onExited}
              key={item.src}
            >
              <div className="page-header header-filter">
                {/* <div
                  className="page-header-image"
                  style={{
                    backgroundImage: item.src,
                  }}
                ></div> */}
                <img src={item.src} />
                <div className="content-center text-center">{item.content}</div>
              </div>
            </CarouselItem>
          );
        })}

        <CarouselControl direction='prev' directionText='Previous' onClickHandler={previous} />
        <CarouselControl direction='next' directionText='Next' onClickHandler={next} />
      </Carousel>
    </>
  );
}

export default EcommerceHeader;
