import React, { useState, useEffect, useContext } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";
import axios from "axios";
import { apiUrl, siteUrl, jwAToken } from "config.js";
import { FilterContext } from "_components"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import styles from "assets/jss/material-kit-react/views/mapPage.js";
const useStyles = makeStyles(styles);

const axiosHeader = {
  headers: { Authorization: `Bearer ${jwAToken}` }
};

const MapArea = withScriptjs(withGoogleMap((props) => {
  const classes = useStyles();
  const openLink = (url) => {
    window.location.replace(url);
  }

  // /////////////////////////////////////
  // data fetching from the API
  const [storefeed, handlestorefeed] = useState([]);
  const filterContextData = useContext(FilterContext); 
  const filty = filterContextData.filty;
  // alert(filterContextData.filty);
  const filter = (filty)?"?categories=like.*"+filty+"*" : " ";

  useEffect(() => {
    FetchData();
  }, [filterContextData.filty]);

  const FetchData = async () => {
    const data = await axios.get(
      `${apiUrl}/sellers_vw${filter}`,
      axiosHeader
    );

    const storefeed = data.data;
    handlestorefeed(storefeed);
  }
  // END fetch

  const [selectedLoc, setSelectedLoc] = useState(null);
  useEffect(() => {
    const listener = e => {
       if (e.key === "Escape") {
          setSelectedLoc(null);
       }
    };
    window.addEventListener("keydown", listener);   
    return () => {
       window.removeEventListener("keydown", listener);
    };
  }, []);

  return (
      <GoogleMap
        center={{lat: 14.6337, lng: 121.0559}}
        zoom={9}
        // options={{streetViewControl: true}}
      >
        { storefeed.map((location) => (
            <Marker position = {{ lat: location.lat, lng: location.lng  }} 
                    key = {location.store_name}
                    onClick = {() => {setSelectedLoc(location)}}
            />
            )
        )}

        { selectedLoc && (
          <InfoWindow onCloseClick={() => {setSelectedLoc(null);}}
                      position={{
                        lat: selectedLoc.lat,
                        lng: selectedLoc.lng
                      }}
          >
            <div>
            <CardContent>
                    <CardActionArea onClick={() => openLink(siteUrl + selectedLoc.subsite_link)} >
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {selectedLoc.store_name}
                    </Typography>
                    </CardActionArea>

                    <Typography variant="subtitle2" gutterBottom color="textSecondary" component="p">
                        {selectedLoc.store_brief}
                    </Typography>                   
  
                </CardContent>
            </div>
          </InfoWindow>
        )}


      </GoogleMap>
    )
}))

export default MapArea;