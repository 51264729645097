import React, { useState } from "react";
// import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LogRocket from 'logrocket';

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/material-kit-react.scss?v=1.8.0";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/css/demo.css?v=1.4.0";
import "assets/css/react-demo.css?v=1.4.0";
import "assets/css/nucleo-icons-page-styles.css?v=1.4.0";

// pages for this product
import LandingPage from "views/LandingPage/Ecommerce.js";
import MapMain from "views/Map/MapMain.js";
import Favorites from "views/Favorites/FavoritesPage.js";
import Latest from "views/Latest/LatestPage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import LogoutPage from "views/LoginPage/LogoutPage.js";
import Sites from "views/Links/Sites.js";
import ManageStoresMain from "views/Stores/ManageStoresMain.js";
import CreateStore from "views/Stores/CreateStore.js";
import EditStore from "views/Stores/EditStore.js";
import EditMap from "views/Stores/EditMap.js";
import Search from "views/Search/Search.js";
import CreateAccount from "components/CreateAccountForm/CreateAccountForm";
import ForgotPassword from "components/ForgotPasswordForm/ForgotPasswordForm";
import ResetPassword from "components/ForgotPasswordForm/ChangePasswordForm";
import PageNotFound from "components/PageNotFound/PageNotFound";
import ContactUs from "components/ContactUs/ContactUs.js";
import Terms from "components/Terms/TermsPage.js";
import Privacy from "components/Privacy/Privacy.js";

// Additional functional Components
// import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";
import { AuthContext } from "_components"
import { PrivateRoute } from "_components/PrivateRoute";
// import { PrivateRoute } from "_components/PrivateRoute"

var hist = createBrowserHistory();
LogRocket.init('7aernw/villagemarket');

function App(props) {
  const existingTokens = JSON.parse(sessionStorage.getItem("tokens"));
  const [authTokens, setAuthTokens] = useState(existingTokens);
  const setTokens = (data) => {
    sessionStorage.setItem("tokens", JSON.stringify(data));
    setAuthTokens(data);
  }

  // const Links = props => {
  //   const { match } = props;

  //   let {subsite_link} = match.params;
  //   return (
  //       <div>
  //         Portfolio component
  //         <p>Url params: {subsite_link}</p>
  //       </div>
  //   );
  //   };

  return (
    <AuthContext.Provider 
      value={{ authTokens, setAuthTokens: setTokens }}
    >
    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
    <Router history={hist}>
      <Switch>
        <Route path="/managestores" component={ManageStoresMain} />
        <PrivateRoute path="/createstore" component={CreateStore} />
        <PrivateRoute path="/editstore/:subsite_link" component={EditStore} />
        <PrivateRoute path="/editmap/:subsite_link" component={EditMap} />
        <Route path="/forgotpass" component={ForgotPassword} />
        <Route path="/resetpass/:vid" component={ResetPassword} />
        <Route path="/createacct" component={CreateAccount} />
        <Route path="/maps" component={MapMain} />
        <Route path="/contactus" component={ContactUs} />
        <Route path="/favorites" component={Favorites} />
        <Route path="/search" component={Search} />
        <Route path="/latest" component={Latest} />
        <Route path="/terms" component={Terms} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/login-page" component={LoginPage} />
        <Route path="/logout-page" component={LogoutPage} />
        <Route path="/store/:subsite_link" component={Sites} />
        <Route path="/" exact component={LandingPage} />
        <Route component={PageNotFound} />
      </Switch>
    </Router>
    {/* </MuiPickersUtilsProvider> */}
    </AuthContext.Provider>
  );
}

export default App;