import React, { useState, useEffect, useContext } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import { FilterContext } from "_components";
import axios from "axios";
import { apiUrl, siteUrl, jwAToken } from "config.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

// Components
// import GridItem from "components/Grid/GridItem.js";
// import FilterArea from "components/FiltersChoosers/FilterArea.js";
import styles from "assets/jss/material-kit-react/views/mapPage.js";

const useStyles = makeStyles(styles);

const axiosHeader = {
    headers: { Authorization: `Bearer ${jwAToken}` }
  };

export default function MiniStoreArea() {
    const classes = useStyles();
  
    // /////////////////////////////////////
    // data fetching from the API
    const [storefeed, handlestorefeed] = useState([]);
    const filterContextData = useContext(FilterContext); 
    const filty = filterContextData.filty;
    // console.log(filterContextData.filty);
    const filter = (filty)?"?categories=like.*"+filty+"*" : " ";
  
    useEffect(() => {
      FetchData()
    }, [filter])
    const FetchData = async () => {
      const data = await axios.get(
            `${apiUrl}/sellers_vw${filter}`,
            axiosHeader
          );
      
      const storefeed = data.data;
      handlestorefeed(storefeed);
    }
    // END fetch
  
    const openLink = (url) => {
        window.location.replace(url);
    }

    // alert(storecount);
    // const storesList = () => {
    //     return(  
    //     storefeed.map((tile) => (
    //         <Card className={classes.section}>
    //         <CardMedia
    //         className={classes.media}
    //         style={{height:0, paddingTop: '35%'}}
    //         image={tile.media_link}
    //         />
    //             <CardContent>
    //                 <CardActionArea onClick={() => openLink(siteUrl + tile.subsite_link)} >
    //                 <Typography className={classes.title} color="textSecondary" gutterBottom>
    //                     {tile.store_name}
    //                 </Typography>
    //                 </CardActionArea>

    //                 <Typography variant="subtitle2" gutterBottom color="textSecondary" component="p">
    //                     {tile.store_brief}
    //                 </Typography>                   
      
    //             </CardContent>
    //         </Card>
    //     )))
    // }

    ////////// USED FOR INFINITE SCROLL
    const [count, setCount] = useState({prev: 0, next: 5})
    const [hasMore, setHasMore] = useState(true);
    const [current, setCurrent] = useState(storefeed.slice(count.prev, count.next))
    const getMoreData = () => {
        if (current.length >= storefeed.length) {
            setHasMore(false);
            return;
        }
        setTimeout(() => {
            setCurrent(current.concat(storefeed.slice(count.prev + 5, count.next + 5)))
        }, 2000)
        setCount((prevState) => ({ prev: prevState.prev + 5, next: prevState.next + 5 }))
    }

    return (  
        <>
        {/* {alert(storefeed.length)} */}

        <InfiniteScroll
        dataLength={storefeed.length} //This is important field to render the next data
        next={getMoreData}
        hasMore={true}
        height={500}
        loader={<h5>...</h5>}
        endMessage={
            <p style={{textAlign: 'center'}}>
            <b>End of List.</b>
            </p>
        }
        >
            {
                storefeed.map((tile) => (
                    <Card className={classes.section}>
                    <CardMedia
                    className={classes.media}
                    style={{height:0, paddingTop: '35%'}}
                    image={tile.media_link}
                    />
                        <CardContent>
                            <CardActionArea onClick={() => openLink(siteUrl + tile.subsite_link)} >
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                {tile.store_name}
                            </Typography>
                            </CardActionArea>

                            <Typography variant="subtitle2" gutterBottom color="textSecondary" component="p">
                                {tile.store_brief}
                            </Typography>                   
            
                        </CardContent>
                    </Card>
            ))}
        </InfiniteScroll>

        </>
    )
};