/*eslint-disable*/
import React, {useState, useEffect, Component } from "react";
import axios from "axios";
import { apiUrl, siteUrl, jwAToken, jwSToken } from "config.js";
import { currentUserEmail, currentUserFaves } from "_components";
// import { FilterContext } from "_components";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Editor from "components/Editor/Editor.readonly.js";
import LoginDialogue from "./LoginDialog.js";
import GoogleMap from "components/Maps/GoogleMap.js";
import Footer from "components/Footer/Footer.js";
import TransitionAlert from "components/Alerts/TransitionAlert.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CallIcon from '@material-ui/icons/Call';
import EmailTwoToneIcon from '@material-ui/icons/EmailTwoTone';
import FacebookIcon from '@material-ui/icons/Facebook';  
// import AppBar from '@material-ui/core/AppBar';  
// import Toolbar from '@material-ui/core/Toolbar';  
import TwitterIcon from '@material-ui/icons/Twitter';  
// import LinkedInIcon from '@material-ui/icons/LinkedIn';  
// import MailIcon from '@material-ui/icons/Mail';  
import InstagramIcon from '@material-ui/icons/Instagram';  
// import YouTubeIcon from '@material-ui/icons/YouTube'; 
import Carousel from 'react-material-ui-carousel';
import LanguageIcon from '@material-ui/icons/Language';
// import LinearProgress from '@material-ui/core/LinearProgress';

import styles from "assets/jss/material-kit-react/views/sitesPages.js";

const useStyles = makeStyles(styles);

const axiosHeader = {
    headers: { Authorization: `Bearer ${jwAToken}` }
  };

const Sites = props =>  {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    ///////////////////////////////////////
    // data fetching from the API
    const { match } = props;
    let {subsite_link} = match.params;
    const filter = (subsite_link) ? "?subsite_link=eq." + subsite_link : " ";

    const [storefeed, handlestorefeed] = useState([]);  
    const [mediafeed, handlemediafeed] = useState([]);  

    const FetchData = async () => {
        const data = await axios.get(
            `${apiUrl}/sellers_vw${filter}`,
            axiosHeader
          );
      
        const storefeed = data.data;
        handlestorefeed(storefeed);
    }
    const storeinfo = (storefeed[0]) || [];
    const contactinfo2 = storeinfo.contact_info_2 || "";
    const contactinfo3 = storeinfo.contact_info_3 || "";
    const vfacebook = storeinfo.facebook ? "https://facebook.com/" + storeinfo.facebook :  "";
    const vinstagram = storeinfo.instagram ? "https://instagram.com/" + storeinfo.instagram : "";
    const vtwitter = storeinfo.twitter ? "https://twitter.com/" + storeinfo.twitter : "";
    const vyoutube = storeinfo.youtube ? "https://youtube.com/" + storeinfo.youtube : "";
    const vwebsite = storeinfo.website || "";
    const vdetails = storeinfo.details || "";
    

    const FetchMedia = async () => {
        const data1 = await axios.get(`${apiUrl}/media_vw${filter}`, axiosHeader); //data source
        const mediafeed = data1.data;
        handlemediafeed(mediafeed);
    }
    const mediainfo = (mediafeed) || [];
    // alert(JSON.stringify(mediainfo));
    // END fetch
    ////////////////////////////////////////////

    ////////////////////////////////////////////
    // FAVE handling section.  Need to know if user is logged in first.
    // Need to toggle between faved and unfaved
    const [isLoggedin, setLoggedin] = useState(currentUserEmail() ? true : false);
    const [isError, setIsError] = useState(false);
    const [plslogin, setPlsLogin] = useState(false);
    const [faved, setFaved] = useState()

    const axiosSHeader = {
        headers: { Authorization: `Bearer ${jwSToken}` }
      };

    // data POST to API
    function postAPI_Savefave() {
        axios.post(
          `${apiUrl}/rpc/vmsavefaves`, 
          {   "vemail": currentUserEmail(),
              "vsubsitelink": subsite_link
          },
          axiosSHeader
        ).then(result => {
          // alert( JSON.stringify(result) );
          if (result.status === 201 || result.status === 200) {
            setIsError(false);
            faved ? alert("Favorite Removed.") : alert("Favorite Saved.") 
          } else if (result.status === 401 ) {
            setIsError(true);
            alert("Unauthorized Access");
          } else if (result.status === 409 ) {
            setIsError(true);
            alert(" Error 409. Contact support@villagemarket.ph for issue.");
          } else {
            setIsError(true);
            // alert( JSON.stringify(result.message) );
          }
        })
        .catch(e => {
          alert("CATCH ERROR: Data RPC Post issue ");
          // alert(JSON.stringify(e) );                                            // REMOVE IN PRODUCTION
          setIsError(true);
        })

    };
    // END POST

    function addFaves() {
      if (isLoggedin) {
        postAPI_Savefave()
        if (faved) {
            setFaved(false)
            let newFaves = localStorage.getItem("faves").replace(subsite_link, "")
            localStorage.setItem("faves", newFaves)
        } else {
            setFaved(true)
            localStorage.setItem("faves", localStorage.getItem("faves") + " " + subsite_link);
        }
        } else {
            // alert("Pls log in.")
            setPlsLogin(true)
        }
    }
    // END Fave handling section.
    ////////////////////////////////////////////

    useEffect(() => {
      FetchData(), 
      FetchMedia(),
      setFaved(currentUserFaves().includes(subsite_link) ? true : false )
    }, []);

    if (isError) {
        return <TransitionAlert severity="error" message="There was an error with saving your Favorites.  Please email support@villagemarket.ph " />
    };

    // HANDLE IMG Loading
    const [imageLoaded, setImageLoaded] = useState(false);

    return (
      <>
        <div className={classes.main}>
        <ScrollTransparentNavbar />
        {/* MAIN SECTION */}
        <div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          // className={classes.cardcontainer}
          // style={{ minHeight: '80vh' }}
        >
            <Card className={classes.root}>
                <div>
                <CardHeader
                    className={classes.header}
                    avatar={
                    <Avatar aria-label="store" className={classes.avatar}>
                       {storeinfo.store_initial}
                    </Avatar>
                    }
                    title={storeinfo.store_name}
                    subheader={storeinfo.store_brief}
                />
                </div>

                <div className={classes.mediacontainer}>
                {!imageLoaded && (
                    // Loading intial image only because multiple images takes too long
                    <div className={classes.mediacontainer}>
                        <img className={classes.media} src={storeinfo.media_link} alt="Initial Store Pic" />
                    </div>
                )}
                <Carousel navButtonsAlwaysVisible="true" indicators="true" className={classes.mediacontainer}> 
                    { mediainfo.map ( (item) =>
                         <img   src={item.media_link} 
                                className={classes.media}
                                alt="Store Pics" 
                                onLoad={() => setImageLoaded(true)} />
                    )}
                </Carousel>

                </div>
                
                <div className={classes.section}>
                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {storeinfo.description_short}
                    </Typography>
                </CardContent>
                </div>

                <div>
                <CardActions disableSpacing>
                    <IconButton aria-label="add to favorites" 
                                onClick={addFaves}
                    >
                        { faved ? <FavoriteIcon /> : <FavoriteBorderIcon /> }
                    </IconButton>
                    { plslogin ? <LoginDialogue /> : null }

                    <IconButton aria-label="share">
                        <ShareIcon />
                    </IconButton>
                 
                    <IconButton
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        color="secondary"
                    >
                        <ExpandMoreIcon /> 
                        <Typography variant="caption">Click to see details </Typography> 
                    </IconButton>
                    
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>

                    <Typography paragraph>Details: </Typography>
                        { (vdetails.length > 1) && <Editor details={storeinfo.details} /> }
        
                    <Typography paragraph>Contact: </Typography>
                    <List>
                        { !!(storeinfo.contact_info_1) && <ListItem> <CallIcon/> {storeinfo.contact_info_1} </ListItem> }
                        { (contactinfo2.length > 5) && <ListItem> <CallIcon/> {storeinfo.contact_info_2} </ListItem> }
                        { (contactinfo3.length > 5) && <ListItem> <CallIcon/> {storeinfo.contact_info_3} </ListItem> }
                        { !!(storeinfo.email) && <ListItem> <EmailTwoToneIcon/> {storeinfo.email} </ListItem> }
                        <ListItem>
                        { (vfacebook.length > 5) && <Link target="_blank" href={vfacebook}> <FacebookIcon/> </Link>}
                        { (vinstagram.length > 5) && <Link target="_blank" href={vinstagram}> <InstagramIcon/> </Link>}
                        { (vtwitter.length > 5) && <Link target="_blank" href={vtwitter}> <TwitterIcon/> </Link>}
                        { (vyoutube.length > 5) && <Link target="_blank" href={vyoutube}> <LanguageIcon/> </Link>}
                        { (vwebsite.length > 5) && <Link target="_blank" href={vwebsite}> <LanguageIcon/> </Link>}
                        </ListItem>
                    </List>
        
                    { !!(storeinfo.lat + storeinfo.lng) &&  <div>   <Typography>Location: </Typography> 
                                                                    <GoogleMap lat={storeinfo.lat} lng={storeinfo.lng} name={storeinfo.store_name} /> 
                                                            </div> }
        
                    </CardContent>
                </Collapse>
                </div>
                </Card>
        </Grid>
        </div>
            <Footer />
        </div>    
      </>
    )
};

export default Sites;