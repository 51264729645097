import React, { useState, useEffect, useContext } from "react";
import { fetchPhotos, openUploadWidget } from "_components";
import { CloudinaryContext, Image } from "cloudinary-react";
import { MediaContext } from "_components";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import styles from "assets/jss/material-kit-react/components/customInputStyle.js";

const useStyles = makeStyles(styles);

export default function MediaChooser() {
  const classes = useStyles();

  const [media, setMedia] = useContext(MediaContext);
  const [images, setImages] = useState([]);
  const beginUpload = tag => {
    const uploadOptions = {
      cloudName: "villagemarket",
      tags: [tag],
      uploadPreset: "zrxn5pvg"
    };
  
  openUploadWidget(uploadOptions, (error, result) => {
      if (!error) {
        console.log(result);
        if(result.event === 'success'){
          setImages([...images, result.info.public_id]);
          setMedia(Object.assign(media, {[result.info.secure_url]: result.info.thumbnail_url} ))     //  Did it this way because spread operation did not work ???  
          // alert(JSON.stringify(media));
        }
      } else {
        console.log(error);
      }
     })
  };

  useEffect( () => {
    fetchPhotos("image", setImages);
  }, [])
    
  return (
    <>
      <CloudinaryContext cloudName="villagemarket">
      <Paper elevation={3} className={classes.paper} >
        <Button onClick={() => beginUpload()} variant="contained" color="primary">
          Upload Image(s)
        </Button>
        <section>
        {images.map(i => <Image
              key={i}
              publicId={i}
              fetch-format="auto"
              quality="auto"
            />)}
      </section>
      </Paper>
      </CloudinaryContext>
    </>
  )
};