import React, { useContext } from "react";
import { AuthContext } from "_components"
import { Link } from "react-router-dom";

// UI
import { makeStyles } from "@material-ui/core/styles";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import image from "assets/img/food3.jpg";
import Warning from "components/Typography/Warning.js";


const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const classes = useStyles();
  // const { ...rest } = props;
  const { setAuthTokens } = useContext(AuthContext)

  function logOut() {
    localStorage.setItem("token",  null );
    sessionStorage.setItem("token",  null );
    setAuthTokens( null );
    // alert(" LogoutPage; " + authTokens );
  }

  return (
    <div>
      {/* <Header/> */}
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <div className="text-center">
            <h3>Logging Out?</h3>
            <Warning>Click below ...</Warning>
            <br/>
          </div>

          <div className="text-center">
            <Link to="/">
            <Button  size="large" variant="contained" color="warning" onClick={logOut}>
              Continue Log Out? 
            </Button>
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/">
            <Button  size="large"variant="contained" color="secondary" round>
              Return to what you were doing
            </Button>
            </Link>
          </div>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
