import { container, title } from "assets/jss/material-kit-react.js";

const mapPageStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    margin: "0px auto 30px 20px",
    minHeight: "20px",
    color: "#24427C",
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#24427C",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  wrapper: {
    margin: "0px 0px 0px 0px",
  },
  paper: {
    height: 80,
    background: "#63afbd",
    margin: "0px 0px 0px 0px",
  },
  filterarea: {
    height: 50,
    background: "#ffffff",
    margin: "10px auto 10px 20px",
    maxWidth: "50%",
    justifyContent: "center",
    alignContent: "center",
  },
  section: {
    background: "rgba(253, 249, 227, 0.9)",
    margin: "0px 0px 10px 0px",
    zIndex: "5"
  },
  map: {
    margin: "20px 0px 10px 0px",
    height: 300,
  },
  search: {
    margin: "20px",
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "60%",
  },
  // pageli_element: {
  //   margin: "20px",
  //   display: "inline-block",
  //   paddingLeft: "20px",
  //   paddingRight: "20px",
  //   flexWrap: "wrap",
  //   maxWidth: "90%",
  // },
};

export default mapPageStyle;
