/*eslint-disable*/
import React, {useState} from "react";
import axios from "axios";
import { apiUrl, jwAToken } from "config.js";
// reactstrap components
import {
  Button,
  Card,
  // CardHeader,
  CardBody,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  // UncontrolledTooltip,
} from "reactstrap";

function Subscribe() {
  const [isError, setIsError] = useState(false);
  const [isDone, setDone] = useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [email, setEmail] = useState(" ");

  function onFormSubmit(e) {
    e.preventDefault();
    // alert("Form Submit")
    postSubMsg() 
  }
  
  // API CALL
  const axiosHeader = {
    headers: { Authorization: `Bearer ${jwAToken}` }
  };
  function postSubMsg() {
      axios.post(
        `${apiUrl}/rpc/vmsubscribe`, 
        {   "vemail": email
        },
        axiosHeader
      ).then(result => {
        // alert( JSON.stringify(result) );
        if (result.status === 201 || result.status === 200) {
          setIsError(false);
          setDone(true);
          alert("Email included in Mailing List.");
        } else if (result.status === 401 ) {
          setIsError(false);
          setDone(true);
          alert("Unauthorized Access");
        } else if (result.status === 409 ) {
          setIsError(false);
          setDone(true);
          alert(" Error 409. Contact support@villagemarket.ph for issue.");
        } else {
          setIsError(true);
          // alert( JSON.stringify(result.message) );
        }
      })
      .catch(e => {
        alert("CATCH ERROR: Data RPC Post issue ");
        // alert(JSON.stringify(e) );
        setIsError(true);
      })
      ;
    }
    
    // if (isDone) {
    //   return <Redirect to="/"/>;
    // };
    
    // if (isError) {
    //   return <TransitionAlert severity="error" message="There was an error. Contact support@villagemarket.ph" />
    // };
      
  return (
    <>
           {/* SUBSCRIBE SECTION */}
          <div
            className="subscribe-line subscribe-line-image"
            style={{
              backgroundImage: "url(" + require("assets/img/banig-bg.jpg") + ")",
            }}
          >
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="6">
                  <div className="text-center">
                    <h4 className="title">Subscribe to our Newsletter</h4>
                    <p className="description">
                      Join our newsletter and get news in your inbox every week!
                      We hate spam too, so no worries about this.
                    </p>
                  </div>
                  <Card className="card-raised card-form-horizontal">
                    <CardBody>
                      <Form id="subscribe-form" onSubmit={onFormSubmit} role="form">
                        <Row>
                          <Col sm="8">
                            <InputGroup
                              className={emailFocus ? "input-group-focus" : ""}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="now-ui-icons ui-1_email-85"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Enter email Here..."
                                type="text"
                                onFocus={() => setEmailFocus(true)}
                                onBlur={() => setEmailFocus(false)}
                                onChange={(e) => setEmail(e.target.value)}
                              ></Input>
                            </InputGroup>
                          </Col>
                          <Col sm="4">
                            <Button block color="info"                         
                                    type="submit">
                              Subscribe
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div> 
    </>
  );
}

export default Subscribe;
