/*eslint-disable*/
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import EcommerceHeader from "components/Header/EcommerceHeader.js";
import Subscribe from "components/Subscribe/Subscribe.js";  
import Footer from "components/Footer/Footer.js";
import ProductSection from "./Sections/ProductSection";
import { FilterContext, FilterProvider } from "_components"
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/landingPage.js";

const useStyles = makeStyles(styles);

function Ecommerce() {
  const classes = useStyles();
  // focus for inputs
  const [emailFocus, setEmailFocus] = React.useState(false);
  // collapse states and functions
  const [collapses, setCollapses] = React.useState([1]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  // // slider states and functions
  // const [sliderMin, setSliderMin] = React.useState(100);
  // const [sliderMax, setSliderMax] = React.useState(880);
  
  return (
    <>
      <ScrollTransparentNavbar />
      <div className="wrapper">
        {/* HEADER SECTION */}
        <EcommerceHeader />

        {/* MAIN SECTION */}
        <div className="main">
          <div className="section">
            <Container fluid={true}>
              <h3 className={classes.title}>Find What You Need</h3>
              <FilterProvider>
                <ProductSection />
              </FilterProvider>
            </Container>
          </div>
          {/* LATEST OFFERS SECTION */}
          {/* <div className="section">
            <Container fluid={true}>
              <h3 className={classes.title}>Latest Stores and Store Updates</h3>
             </Container>
          </div> */}

          {/* SUBSCRIBE SECTION */}
          <Subscribe />

        </div>
        <Footer />
      </div>
    </>
  );
}

export default Ecommerce;
