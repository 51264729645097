import React, { useState, useEffect, useContext, Fragment } from "react";
import { apiUrl } from "config.js";
import { FilterContext } from "_components"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";

// import styles from "assets/jss/material-kit-react/views/componentsSections/pillsStyle.js";
// const useStyles = makeStyles(styles);

export default function FilterArea() {
    // const classes = useStyles();
    const filterData = useContext(FilterContext);
    const [categoryfeed, handlecategoryfeed] = useState([]);

    useEffect(() => {
      FetchData()
    }, [])

    const FetchData = async () => {
        const data = await fetch(`${apiUrl}/category_vw`); //data source
        const categoryfeed = await data.json();
        handlecategoryfeed(categoryfeed);
    }
    // END fetch

    const handleChange = (event) => {
      // alert(event.target.value);
      filterData.setFilty(event.target.value);
    };
    
    return (
      <Fragment>
        <Paper elevation={3}>
            <FormControl>
              <Select
                  labelId="select-cat-label"
                  id="select-cat"
                  onChange={handleChange}
              >
            
              {categoryfeed.map(cat => {
                return <MenuItem value={cat.category_sid}> {cat.category_name} </MenuItem>
              })}
              
              </Select>
            </FormControl>
        </Paper>
      </Fragment>
    )
};