export const apiUrl = "https://holistica.subzero.cloud/rest";
export const siteUrl = "https://villagemarket.ph/store/";
export const editsiteUrl = "https://villagemarket.ph/editstore/"
// export const siteUrl = "https://villagemarketdev.web.app/store/";
// export const editsiteUrl = "https://villagemarketdev.web.app/editstore/"
// export const apiUrl = "http://10.10.0.15:3000";
// export const siteUrl = "http://10.10.0.15:8000/store/";
// export const editsiteUrl = "http://10.10.0.15:8000/editstore/";
// export const apiUrl = "http://192.168.1.113:3000";
// export const siteUrl = "http://192.168.1.113:8000/store/";
// export const editsiteUrl = "http://192.168.1.113:8000/editstore/";
export const jwAToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoidm1fYW5vbl9yb2xlIn0.pnQXPZQHNCty_rBqUcn-bix2HPf0-d2eclRo8RHQiy0";
export const jwBToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoidm1fYnV5ZXJfcm9sZSJ9.vNFumTmqVQtXCeZoKLNUr3V5ZZWs_msVhM00B3Pz4oc";
export const jwSToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoidm1fc2VsbGVyX3JvbGUifQ.LdJrulT1ZCT2VLjaaL1j_k8Q8O7VcVjPYVHVkkw2qlU";
export const supportEmail = "support@villagemarket.ph";
export const Version = "v.2021.05.18"