import React, {useState} from 'react';
import { currentUserEmail } from "_components";
import axios from "axios";
import { apiUrl, jwSToken} from "config.js";
import TransitionAlert from "components/Alerts/TransitionAlert.js";


import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';


export default function MediaDelete(props) {
  const [open, setOpen] = React.useState(true);
  const [isError, setIsError] = useState(false);
  const [isDone, setDone] = useState(false);
 
  const handleClose = () => {
    setOpen(false);
  };

  // console.log("Called: "  + JSON.stringify(props.vmedia_link))
  // console.log("Subsite: " + JSON.stringify(props.vsubsite_link))
  const vmedia = JSON.stringify(props.vmedia_link).replace(/"/g, '')
  const vsubsite = JSON.stringify(props.vsubsite_link).replace(/"/g, '')

  // /////////////////////////
  // API CALL
  const axiosHeader = {
    headers: { Authorization: `Bearer ${jwSToken}` }
  };

  function handleDelete(e) {
    e.preventDefault();
    setOpen(false);
    apiMediaDelete();
  };

  function apiMediaDelete() {
    console.log("Vmedia: " + vmedia)
    console.log("vsubsite: " + vsubsite)
    axios.post(
      `${apiUrl}/rpc/vmdelmedia`, 
      {   "vemail": currentUserEmail(),
          "vmedia": vmedia,
          "vsubsite": vsubsite
      },
      axiosHeader
    ).then(result => {
      // alert( JSON.stringify(result) );
      if (result.status === 201 || result.status === 200) {
        setIsError(false);
        setDone(true);
        alert("Your updates will show on next refresh.");
      } else if (result.status === 401 ) {
        setIsError(false);
        setDone(true);
        alert("Unauthorized Access");
      } else if (result.status === 409 ) {
        setIsError(false);
        setDone(true);
        alert(" Error 409. Contact info@villagemarket.ph for issue.");
      } else {
        setIsError(true);
        // alert( JSON.stringify(result.message) );
      }
    })
    .catch(e => {
      alert("CATCH ERROR: Media Delete RPC Post issue ");
      alert(JSON.stringify(e) );
      setIsError(true);
    })
    ;
  };

  if (isDone) {
    // return <Redirect to="/managestores"/>;
  };

 return (
        <>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Media Deletion"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                We are going to permanently delete this picture/media from your Store site and our records.  This cannot be undone.  Are you sure?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
              <Button onClick={handleDelete} color="secondary" variant="contained"  startIcon={<DeleteIcon />} autoFocus>
                DELETE
              </Button>
            </DialogActions>
          </Dialog>

          {isError && <TransitionAlert severity="error" message="There was an error. " />}
        </>
  );
}