import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Navbars/ScrollTransparentNavbar.js";

import styles from "assets/jss/material-kit-react/views/loginPage.js";
import image from "../../assets/img/404-error.jpg";

const useStyles = makeStyles(styles);

export default function PageNotFound(props) {

  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Page Not Found. Make sure you entered the correct URL."
        // rightLinks={<HeaderLinks />}
        {...rest}
      />
      
      <div
        className={classes.root}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
        }}
      >

      </div>
    </div>
  );
}
