import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiUrl, jwAToken } from "config.js";
import MediaDelete from "./MediaDelete.js";

// UI
// import Success from "components/Typography/Success.js";
import GridContainer from "components/Grid/GridContainer.js";
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
// import CardContent from "@material-ui/core/CardContent";
// import Typography from '@material-ui/core/Typography';
// import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";
const useStyles = makeStyles(styles);

const axiosHeader = {
    headers: { Authorization: `Bearer ${jwAToken}` }
  };

const MediaCarousel = (orig_subsite_link) => {
    const classes = useStyles();

    // /////////////////////////////////////
    // data fetching from the API
    // alert(JSON.stringify(orig_subsite_link.subsite_link))
    let subsite_link = JSON.stringify(orig_subsite_link.subsite_link).replace(/"/g, '')
    const filter = (subsite_link) ? "?subsite_link=eq." + subsite_link : " ";

    const [mediafeed, handlemediafeed] = useState([]);  
    const [imageLoaded, setImageLoaded] = useState(false);      // HANDLE IMG Loading
    // const [refresh, setRefresh] = useState(0);      // HANDLE IMG Refresh, referenced by useState

    useEffect(() => {
        FetchMedia()
    }, []);

    const FetchMedia = async () => {
        const data1 = await axios.get(`${apiUrl}/media_vw${filter}`, axiosHeader); //data source
        const mediafeed = data1.data;
        handlemediafeed(mediafeed);
    }
    const mediainfo = (mediafeed) || [];
    // alert(JSON.stringify(mediainfo));
    // END fetch
    ////////////////////////////////////////////
 

    ////////////////////////////////////////////
    // Delete Image 
    const [deleteMedia, setDeleteMedia] = useState();
    const [media, setMedia] = useState();

    const handleClickOpen = (vmedia_link) => {
      setDeleteMedia(true)
      setMedia(vmedia_link)
    };
    // END Delete Image 
    ////////////////////////////////////////////
 

    return (
      <div className={classes.section}>
        <GridContainer direction="row" alignItems="flex-start">
            { mediainfo.map ( (item) =>
              <Card className={classes.card}>
                <GridContainer direction="column" alignItems="flex-start" className={classes.card}>
                    <img  src={item.thumb} 
                          alt="Store Pics" 
                          className={`smooth-image image-${
                            imageLoaded ? 'visible' : 'hidden'
                          }`, classes.card}
                          onLoad={() => setImageLoaded(true)}
                          
                    />
                    <Button variant="contained" 
                            color="secondary" 
                            startIcon={<DeleteIcon />}
                            onClick={() => handleClickOpen(item.thumb)}
                    >

                      Delete 
                    </Button>
                </GridContainer>
              </Card>
            )}
            {deleteMedia &&   <MediaDelete vmedia_link={media} vsubsite_link={subsite_link}/> }
        </GridContainer>
      </div>
    )
};

export default MediaCarousel;