/*eslint-disable*/
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
// import Subscribe from "components/Subscribe/Subscribe.js";  
// import Footer from "components/Footer/Footer.js";
import MapArea from "./MapArea.js";
import MiniStoreArea from "./MiniStoreArea.js";
import FilterArea from "components/FiltersChoosers/FilterArea.js";
import { FilterProvider } from "_components"
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import styles from "assets/jss/material-kit-react/views/mapPage.js";

// Components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import { apiUrl, siteUrl } from "config.js";

const useStyles = makeStyles(styles);

function MapMain() {
  const classes = useStyles();
  // focus for inputs
  const [emailFocus, setEmailFocus] = React.useState(false);
  // collapse states and functions
  const [collapses, setCollapses] = React.useState([1]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  return (
    <>
      <ScrollTransparentNavbar />
      <div className="wrapper">
        {/* HEADER SECTION */}
        <Paper elevation={0} className={classes.paper} />

        {/* MAIN SECTION */}
        <div className="main">
          <div className="section" >
          <FilterProvider>
                <Paper elevation={0} className={classes.filterarea}>
                    <FilterArea />
                </Paper>
            <Container fluid={true}>
              {/* <h3 className={classes.title}>Find What You Need</h3> */}
                <GridContainer spacing={3}>
                    <GridItem xs={12} sm={5} md={4} lg={3}>
                        <MiniStoreArea/>
                    </GridItem>
                    <GridItem xs={12} sm={7} md={8} lg={9}>
                        <MapArea 
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyARZDM3T4lyI2rNc00GtMSDzaDQQJFtINQ&v=3.exp&libraries=geometry,drawing,places"
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `500px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        />
                    </GridItem>  
                </GridContainer>
            </Container>
          </FilterProvider>
          </div>
        </div>
            {/* SUBSCRIBE SECTION */}
            {/* <Subscribe />
            <Footer /> */}
      </div>
    </>
  );
};

export default MapMain;