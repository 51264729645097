import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { useAuth, currentUserRole } from "_components";
import { apiUrl, jwAToken } from "config.js";
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';

// UI
import "assets/css/LoginForm.css";
import TransitionAlert from "components/Alerts/TransitionAlert.js";
// import { TrainRounded } from "@material-ui/icons";
// import Dialog from '@material-ui/core/Dialog';

const SocialMedia = ( {greeting} ) => {
  const { setAuthTokens } = useAuth();
  // const [open, setOpen] = React.useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isDone, setDone] = useState(false);
  const [role, setRole] = useState("Buyer");
  const fbgreeting = `${greeting}` + " with Facebook"
  const axiosHeader = {
    headers: { Authorization: `Bearer ${jwAToken}` }
  };
 
  // function handleClose(value) {
  //       setOpen(false);
  //       setRole(value);
  //   }
  
  function postLogin(postData) {
    // alert("postData: " +  postData.provider_id + postData.email)

    axios.post(
        `${apiUrl}/rpc/vmlogin_social`, 
        {
          "email": postData.email,
          "vid": postData.provider_id,
        },
        axiosHeader      
      ).then(result => {
          if (result.status === 200) {
            setAuthTokens(result.data);
            setLoggedIn(true)
            setIsError(false);
            sessionStorage.setItem("tokens", JSON.stringify(result.data));
          } else {
            alert("Create Account or Sign Up first. ")
            setDone(true); 
          }
    })
    .catch(e => {
      console.log("Error: " + e)
      alert("Create Account or Sign Up first. (e)")
      setDone(true); 
      // INSTEAD OF showing error, redirect user to create account.  setIsError(true);
    })
    ;
  }  // END postLogin

  if (isDone) {
     return <Redirect to="/createacct"/>;
  }

  if (isLoggedIn) {
    if (currentUserRole() === "vm_seller_role") { 
      return <Redirect to="/managestores"/>;
    }
    else {
      return <Redirect to="/"/>;
    }
  }

  if (isError) {
    return (<>
          <TransitionAlert severity="error" message="Email support@villagemarket.ph.  With subject of 'Social Login Issue'"/>
        </>)
  }

  const responseFacebook = (res) => {
    // setShowroleDialog(true);
    console.log(JSON.stringify(res))
    if (res.name) {
        let postData;

        postData = {
          // firstName: res.name,
          // lastName: res.name,
          // provider: "Facebook",
          email: res.email,
          provider_id: res.id,
          token: res.accessToken,
          provider_pic: res.picture.data.url
        }; 
        postLogin(postData)
    } else {
        setLoggedIn(false)
    }
  }


  const responseGoogle = (res) => {
    // setShowroleDialog(true)
    if (res.profileObj) {
      let postData;

      postData = {
        // firstName: res.profileObj.givenName,
        // lastName: res.profileObj.familyname,
        // provider: "Google",
        email: res.profileObj.email,
        provider_id: res.googleId,
        token: res.accessToken,
        provider_pic: res.profileObj.imageUrl
      }; 
      // alert("GG Response: " + JSON.stringify(postData) );
      postLogin(postData)
     
    } else {
      setLoggedIn(false)
    }

  }
  
 return(  
    <>
        <div className="loginbutton">
          {!isLoggedIn &&
          <div> 
            <FacebookLogin
                appId="753810018541223"
                autoLoad
                fields="name,email,picture"
                // onClick={componentClicked}
                callback={responseFacebook}
                cssClass="btnFacebook"
                icon="fa-facebook"
                textButton = {fbgreeting}   
            />
            <GoogleLogin
                clientId={'722516736932-hdjcnb95f935kl5eb1puovvset37i928.apps.googleusercontent.com'}
                onSuccess={responseGoogle}
                // onClick={componentClicked}
                // isSignedIn={true}
                className="btnGoogle"
            >
                 <span>{greeting} with Google</span>
            </GoogleLogin>
          </div>
          }
        </div>

        {/* {showroleDialog ? <RoleDialog role={role} open={open} onClose={handleClose} /> : "" } */}
    </>
  );
};

export default SocialMedia;