import React, {useState} from 'react';
import {EditorState, convertFromRaw, RichUtils} from 'draft-js';
import Editor from "draft-js-plugins-editor";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import 'draft-js/dist/Draft.css';
import Divider from '@material-ui/core/Divider';
import addLink from '_helpers/addLinkPlugin';

// SOURCES:
//          https://codepen.io/Kiwka/pen/YNYvyG?editors=0010
//      **  https://dev.to/rose/rich-text-editing-on-the-web-getting-started-with-draft-js-2f68
//          https://draftjs.org/docs/getting-started

const divStyle = {
  margin: "15px 5px 5px 5px",
  border: "2px blue",
  color: "black",
}

const editStyle = {
  color: "black",
}

function MyEditor(props) {
  let content = props.details

  const [editorState, setEditorState] = useState(() => EditorState.createWithContent(convertFromRaw(JSON.parse(content))), );
  const [plugins, setPlugins] = useState([ addLink, ]);

  function handleKeyCommand(command) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return true;
    }
    return false;
  }

  return( 
    <>
        <div style={divStyle}>
          <Paper elevation={3}>
            {/* <Typography variant="subtitle2" gutterBottom style={divStyle}>
                Details
            </Typography> */}
            <Divider variant="middle"/>
            <Typography variant="body2" gutterBottom style={divStyle}>
              <Editor 
                  editorState={editorState} 
                  onChange={setEditorState} 
                  handleKeyCommand={handleKeyCommand}
                  plugins={plugins}
                  style={editStyle}
                  readOnly={true}
              />
            </Typography>
          </Paper>

          <br/> 
        </div>
    </>
   )
      
}

export default MyEditor;