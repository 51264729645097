import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { apiUrl, jwAToken } from "config.js";

//  UI
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import srcimage from "../../assets/img/illustration-sample.jpg";

const useStyles = makeStyles(styles);

const ForgotPasswordForm = ({
  backgroundColor, isFlat, heading, subheading, helpText, image, headingColor, subheadingColor,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState(undefined);
  const [isDone, setDone] = useState(false);
  const [isError, setIsError] = useState(false);

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();

  const axiosHeader = {
    headers: { Authorization: `Bearer ${jwAToken}` }
  };

  function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitted(true);
    const isFormValid = e.target.checkValidity();
    if (isFormValid) {
      // Success
      alert(`Sending password reset link to ${email}`);
      postReset();
    } else {
      // Form has errors
    }
  }

  function sendEmail(user) {
    let mailadd = `https://villager.pythonanywhere.com/api/v1/sendresetpass?user=${user}`
    axios.get(mailadd)
         .then(result => {
            if (result.status === 201 || result.status === 200) {
              alert("You will receive an email to reset your password. v.01. " );
            } 
         })
        .catch(e => {
          // alert("CATCH ERROR: Validate Post issue.  ");
          console.log(JSON.stringify(e) );
        })
  }

  function postReset() {
    axios.post(`${apiUrl}/rpc/vmresetpass`, 
      {"vemail": email},
      axiosHeader      )
      .then(result => {
      // alert( JSON.stringify(result) );
      if (result.status === 200 ) {
        setIsError(false);
        setDone(true);
        sendEmail(email);
        alert('You will receive an email to reset your password. ');
      } else {
        setIsError(true);
        // alert( JSON.stringify(result.message) );
        setDone(true);
      }
    })
    .catch(e => {
      alert("CATCH ERROR")
      setIsError(true);
      setDone(true);
    })
    ;
  };

  if (isError) {
    alert("There was an error or email doesn't exist in our system.  Please email support@holistica.ph with details of issue.");
  };
  if (isDone) {
    return <Redirect to="/"/>;
  };

  return (
  <div
    style={{
      backgroundImage: "url(" + srcimage + ")",
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "100vh",
    }}
  >
  <GridContainer justify="center">
    <GridItem xs={12} sm={10} md={8} lg={6}>
    <Card className={classes[cardAnimaton]}>
      <div className={classes.form}>
        <form className={`p-4 ${isFlat ? '' : 'shadow-sm rounded-sm bg-white'} ${isSubmitted ? 'was-validated' : ''}`} noValidate onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="emailInput">Email address</label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              className="form-control form-control-sm"
              id="emailInput"
              aria-describedby="emailHelp"
              required
            />
            <div className="invalid-feedback">
              Please enter an email address in the correct format.
            </div>
            {helpText && (
              <small id="emailHelp" className="form-text text-muted">
                {helpText}
              </small>
            )}
          </div>
          <button type="submit" className="btn btn-primary btn-block btn-sm">Send Password Reset Email</button>
          <div className="d-flex justify-content-between mt-4">
            <Link to="/login-page">
              <button type="button" className="btn btn-link btn-sm p-0">&#8592; Log In</button>
            </Link>
          </div>
        </form>
        </div>
        </Card>
      </GridItem>
  </GridContainer>
  </div>
  );
};

ForgotPasswordForm.propTypes = {
  backgroundColor: PropTypes.string,
  isFlat: PropTypes.bool,
  heading: PropTypes.string,
  headingColor: PropTypes.string,
  subheading: PropTypes.string,
  subheadingColor: PropTypes.string,
  helpText: PropTypes.string,
  image: PropTypes.node,
};

ForgotPasswordForm.defaultProps = {
  backgroundColor: 'white',
  isFlat: false,
  heading: '',
  headingColor: 'black',
  subheading: '',
  subheadingColor: 'black',
  helpText: '',
  image: undefined,
};

export default ForgotPasswordForm;
