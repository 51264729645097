import React, { useState, useEffect, useContext, Fragment } from "react";
import { FilterContext } from "_components";
import axios from "axios";
import { apiUrl, siteUrl, jwAToken } from "config.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FilterArea from "components/FiltersChoosers/FilterArea.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
// import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons
// import Chat from "@material-ui/icons/Chat";
// import VerifiedUser from "@material-ui/icons/VerifiedUser";
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
const useStyles = makeStyles(styles);

const axiosHeader = {
  headers: { Authorization: `Bearer ${jwAToken}` }
};

export default function ProductSection() {
  const classes = useStyles();
  
  // /////////////////////////////////////
  // data fetching from the API
  const [storefeed, handlestorefeed] = useState([]);
  const filterContextData = useContext(FilterContext); 
  const filty = filterContextData.filty;
  console.log(filterContextData.filty);
  const filter = (filty)?"?categories=like.*"+filty+"*" : " ";

  useEffect(() => {
    FetchData()
  }, [filter])
  const FetchData = async () => {
    const data = await axios.get(
      `${apiUrl}/sellers_vw${filter}`,
      axiosHeader
    );
    // const storefeed = await data.json();
    const storefeed = data.data;
    // alert(JSON.stringify(storefeed));
    handlestorefeed(storefeed);
  }
  // END fetch

  const openLink = (url) => {
      window.location.replace(url);
  }

  const StoreArea = () => (
    <Fragment>
             {storefeed.map((tile) => (
              <GridItem sm={12} md={6} lg={4} xl={3} >
                <Card className={classes.section}>
                <CardMedia
                className={classes.media}
                style={{height:0, paddingTop: '35%'}}
                image={tile.media_link}
                />
                    <CardContent>
                        <CardActionArea onClick={() => openLink(siteUrl + tile.subsite_link)} >
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            {tile.store_name}
                        </Typography>
                        </CardActionArea>

                        <Typography variant="subtitle2" gutterBottom color="textSecondary" component="p">
                            {tile.store_brief}
                        </Typography>                   
                        <Typography variant="body2" component="p">
                            {tile.description_short}
                        </Typography>
                    </CardContent>
                </Card>
              </GridItem>
            ))}
    </Fragment>
  );

  return (
        <GridContainer spacing={3}>
          <GridItem xs={12} sm={4} md={2} >
            <FilterArea/>
          </GridItem>
          <GridItem xs={12} sm={8} md={10} >
            <GridContainer spacing={3}>
              <StoreArea/>
            </GridContainer>
          </GridItem>  
        </GridContainer>
      
  );
}
