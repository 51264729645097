/*eslint-disable*/
import React from "react";
import YourStoresList from "./YourStoresList.js";
import { CategoryProvider, MapProvider } from "_components";
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// UI components
import Subscribe from "components/Subscribe/Subscribe.js";  
import Footer from "components/Footer/Footer.js";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/mapPage.js";

const useStyles = makeStyles(styles);

function ManageStoresMain() {
  const classes = useStyles();
  // focus for inputs
  const [emailFocus, setEmailFocus] = React.useState(false);
  // collapse states and functions
  const [collapses, setCollapses] = React.useState([1]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  // // slider states and functions
  // const [sliderMin, setSliderMin] = React.useState(100);
  // const [sliderMax, setSliderMax] = React.useState(880);
  
  return (
    <>
      <ScrollTransparentNavbar />
      <div className="wrapper">
        {/* HEADER SECTION */}
        <Paper elevation={0} className={classes.paper} />

        {/* MAIN SECTION */}
        <CategoryProvider>
          <MapProvider>
              <YourStoresList />
          </MapProvider>
        </CategoryProvider>

        {/* SUBSCRIBE SECTION */}
        <Subscribe />
        <Footer />
      </div>
    </>
  );
}

export default ManageStoresMain;
