import React, { createContext, useState } from "react";

export const FilterContext = createContext();

// This context provider is passed to any component requiring the context
export const FilterProvider = ({children}) => {
  const [filty, setFilty] = useState();

  return (
    <FilterContext.Provider
      value = {{ filty, setFilty }}
    >
      {children}
    </FilterContext.Provider>
  )
}
