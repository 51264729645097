import React, { useState, useEffect, useContext } from "react";
import { apiUrl } from "config.js";
import { CategoryContext } from "_components";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from "@material-ui/core/Checkbox";

import styles from "assets/jss/material-kit-react/components/customInputStyle.js";
const useStyles = makeStyles(styles);
const divStyle = {
  margin: "15px 5px 5px 5px",
  border: "2px blue",
}

const CategoryChooser = (initialCats) => {
    const classes = useStyles();
    const [cat, setCat] = useContext( CategoryContext );
    const [checkedcats, setCheckedcats] = useState("");
    // const [isChecked, setChecked] = useState();
    useEffect(() => {
      setCheckedcats(initialCats.initialCats || "ABC")
    }, [initialCats.initialCats])

    // console.log(initialCats.initialCats);   
    // console.log("CHECKED CATS:" + checkedcats)

    // DATA Loading 
    const [categoryfeed, handlecategoryfeed] = useState([]);
    useEffect(() => {
      FetchData()
    }, [])
    const FetchData = async () => {
        const data = await fetch(`${apiUrl}/category_vw`); //data source
        const categoryfeed = await data.json();
        handlecategoryfeed(categoryfeed);
    }
    // END fetch

    const handleChange = (event) => {
        // setCat(cat => ({ ...cat, [event.target.name] : event.target.checked }));
        setCat(Object.assign(cat, {[event.target.name] : event.target.checked} ))     //  Did it this way because spread operator did not work ???  
        setCheckedcats( event.target.checked ? checkedcats.concat(", ", event.target.name) : checkedcats.replace(event.target.name, "") )
        // console.log(cat);
        // console.log("CHECKED CATS in handle:" + checkedcats)
    };
    
    return (
      <>
        <Paper elevation={3} className={classes.paper} >
            <FormControl component="fieldset" className={classes.formControl}>
                <Typography variant="subtitle2" gutterBottom style={divStyle}>
                    Categories *
                </Typography>
                <FormGroup row>

                {categoryfeed.map( rec => {
                    return (<FormControlLabel
                            control={<Checkbox value={rec.category_sid} 
                                               onClick={handleChange} 
                                               name={rec.category_sid} 
                                               checked={ checkedcats.includes(rec.category_sid) ? true : false }
                                    />}
                            label={rec.category_name}
                            className={classes.formLabel}
                           />)
                })}
       
                </FormGroup>
                <FormHelperText> Choose one or more categories for your store. </FormHelperText>
            </FormControl>
        </Paper>
      </>
    )
}

export default CategoryChooser;