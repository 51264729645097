import React, { useState, useEffect, Fragment } from "react";
// import { FilterContext } from "_components";
// import FilterArea from "components/FiltersChoosers/FilterArea.js";
import axios from "axios";
import { apiUrl, siteUrl, jwAToken } from "config.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { currentUserFaves, currentUserRole } from "_components";

import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footer/Footer.js";
import TransitionAlert from "components/Alerts/TransitionAlert.js";

// UI
import { makeStyles } from "@material-ui/core/styles";
// import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
// import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import styles from "assets/jss/material-kit-react/views/sitesPages.js";
const useStyles = makeStyles(styles);

const axiosHeader = {
  headers: { Authorization: `Bearer ${jwAToken}` }
};

export default function FavoritePages() {
  const classes = useStyles();
  
  // /////////////////////////////////////
  // data fetching from the API
  const [storefeed, handlestorefeed] = useState([]);

  useEffect(() => {
    FetchData()
  }, [])

  const FetchData = async () => {
    const data = await axios.get(
      `${apiUrl}/sellers_vw`,
      axiosHeader
    );
    // const storefeed = await data.json();
    const storefeed = data.data;
    // alert(JSON.stringify(storefeed));
    handlestorefeed(storefeed);
  }
  // END fetch

  const openLink = (url) => {
      window.location.replace(url);
  }

  const StoreArea = () => (
    <Fragment>
      { storefeed.map((tile) => (
          currentUserFaves().includes(tile.subsite_link) 
          ? <GridItem sm={12} md={6} lg={3} xl={3} >
                <Card className={classes.section}>
                <CardMedia
                className={classes.media}
                style={{height:0, paddingTop: '35%'}}
                image={tile.media_link}
                />
                    <CardContent>
                        <CardActionArea onClick={() => openLink(siteUrl + tile.subsite_link)} >
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            {tile.store_name}
                        </Typography>
                        </CardActionArea>

                        <Typography variant="subtitle2" gutterBottom color="textSecondary" component="p">
                            {tile.store_brief}
                        </Typography>                   
                        <Typography variant="body2" component="p">
                            {tile.description_short}
                        </Typography>
                    </CardContent>
                </Card>
            </GridItem>
          : null
        ))
      }
    </Fragment>
  );

  return (
    <div className={classes.main}>
      <div>
        <ScrollTransparentNavbar />
      </div>
      <div className={classes.section}>
      {/* MAIN SECTION */}

      { currentUserRole() === "vm_seller_role" || currentUserRole() === "vm_buyer_role"
      ?
        <GridContainer spacing={3}>
        <h2 className={classes.title}>My Favorites</h2>
          {/* <GridItem xs={12} sm={4} md={2} >
            <FilterArea/>
          </GridItem> */}
          <GridItem xs={12} sm={12} md={12} >
            <GridContainer spacing={3}>
              <StoreArea/>
            </GridContainer>
          </GridItem>  
        </GridContainer>
      : <TransitionAlert message="Please Log In or create an account to access the Favorites section." severity="info"  />
      }

      </div>
      <Footer />
    </div>
      
  );
}
