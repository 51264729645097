import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { apiUrl, jwSToken } from "config.js";

//  UI
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import srcimage from "../../assets/img/illustration-sample.jpg";

import styles from "assets/jss/material-kit-react/views/loginPage.js";
const useStyles = makeStyles(styles);

const ChangePasswordForm = props => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  // const [email, setEmail] = useState(undefined);
  const [isDone, setDone] = useState(false);
  const [isError, setIsError] = useState(false);
  const [password, setPassword] = useState();

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();

  const axiosHeader = {
    headers: { Authorization: `Bearer ${jwSToken}` }
  };

  ///////////////////////////////////////
  // data fetching from the API
  const { match } = props;
  let {vid} = match.params;

  function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitted(true);
    const isFormValid = e.target.checkValidity();
    if (isFormValid) {
      // Success
      postReset();
    } else {
      alert(" Form has errors. ");
    }
  }

  function postReset() {
    alert("Password Change ... ");
    axios.post(`${apiUrl}/rpc/vmchangepass`, 
      { "vid": vid,
        "vpass": password
      },
      axiosHeader      )
      .then(result => {
      // alert( JSON.stringify(result) );
      if (result.status === 200 ) {
        setIsError(false);
        setDone(true);
        alert("Success");
      } else {
        setIsError(true);
        console.log( JSON.stringify(result.message) );
        setDone(true);
      }
    })
    .catch(e => {
      alert("Catch Error")
      setIsError(true);
      setDone(true);
    })
    ;
  };

  if (isError) {
    alert("Password change error. Email token may have expired.  Please reset again.  Email support@holistica.ph if issue persists.");
  };
  if (isDone) {
    return <Redirect to="/"/>;
  };

  return (
  <div
    style={{
      backgroundImage: "url(" + srcimage + ")",
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "100vh",
    }}
  >
  <GridContainer justify="center">
    <GridItem xs={12} sm={10} md={8} lg={6}>
    <Card className={classes[cardAnimaton]}>
      <div className={classes.form}>
        <form className={`p-4 'shadow-sm rounded-sm bg-white'} ${isSubmitted ? 'was-validated' : ''}`} noValidate onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="form-row">
              <div className="col">
                <label htmlFor="passwordInput">Password * </label>
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  type="password"
                  className="form-control form-control-sm"
                  id="passwordInput"
                  required
                />
                <div className="invalid-feedback">
                  Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters
                </div>
              </div>
              <div className="col">
                <label htmlFor="confirmPasswordInput">Confirm Password</label>
                <input
                  pattern={`${password}`}
                  type="password"
                  className="form-control form-control-sm"
                  id="confirmPasswordInput"
                  required
                />
                <div className="invalid-feedback">
                  Please make sure that your passwords match.
                </div>
              </div>
            </div>
          </div>
          {/* END of PASSWORD GROUP */}

          <button type="submit" className="btn btn-primary btn-block btn-sm">Save New Password</button>
          <div className="d-flex justify-content-between mt-4">
            <Link to="/login-page">
              <button type="button" className="btn btn-link btn-sm p-0">&#8592; Log In</button>
            </Link>
          </div>
        </form>
        </div>
        </Card>
      </GridItem>
  </GridContainer>
  </div>
  );
};

ChangePasswordForm.propTypes = {
  backgroundColor: PropTypes.string,
  isFlat: PropTypes.bool,
  heading: PropTypes.string,
  headingColor: PropTypes.string,
  subheading: PropTypes.string,
  subheadingColor: PropTypes.string,
  helpText: PropTypes.string,
  image: PropTypes.node,
};

ChangePasswordForm.defaultProps = {
  backgroundColor: 'white',
  isFlat: false,
  heading: '',
  headingColor: 'black',
  subheading: '',
  subheadingColor: 'black',
  helpText: '',
  image: undefined,
};

export default ChangePasswordForm;
