import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Primary from "components/Typography/Primary.js";
import TransitionAlert from "components/Alerts/TransitionAlert.js";
import axios from "axios";
import { apiUrl, editsiteUrl, jwSToken} from "config.js";
import { currentUserEmail, currentUserRole } from "_components";

// UI
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
// import Alert from '@material-ui/lab/Alert';
import { Col, Button } from "reactstrap";
import Divider from '@material-ui/core/Divider';
import Muted from "components/Typography/Muted.js";
// import Link from '@material-ui/core/Link';
// import image from "assets/img/faces/avatar.jpg";
import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";

const useStyles = makeStyles(styles);
const axiosHeader = {
  headers: { Authorization: `Bearer ${jwSToken}` }
};

const openLink = (url) => {
  window.location.replace(url);
}

export default function YourStoresList() {
  const classes = useStyles();

  // /////////////////////////////////////
  // data fetching from the API
  const [storefeed, handlestorefeed] = useState([]);
  // const userContextData = useContext(FilterContext); 
  const filter = (currentUserEmail())?"?email=eq." + currentUserEmail() : " ";
  
  useEffect(() => {
    FetchData()
  }, [])

  const FetchData = async () => {
    const data = await axios.get(
      `${apiUrl}/storebysellers_vw${filter}`,
      axiosHeader
    );
    const storefeed = data.data;
    // alert(JSON.stringify(storefeed));
    handlestorefeed(storefeed);
  }
  // END fetch

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div id="typography">
          <div className={classes.title}>
            <h4>Manage My Stores</h4>
          </div>

          {/* if authenticated then Render. If not redirect to Login.  If empty stores list give option to register store */}
          { currentUserRole() === "vm_seller_role"
            ? <>
              <GridContainer>
                {storefeed && <GridItem><div><Muted> Click on store name below to edit store.  Red stores are not verified and are not published. Edit, make changes and save to get verification email.</Muted></div> </GridItem>}
                {storefeed.map((store) => ( 
                  <Card className={ store.verified ? classes.card : classes.cardnotverified }>
                    <CardContent>
                      <CardActionArea onClick={() => openLink(editsiteUrl +  store.subsite_link)} >
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                          {store.store_name}
                      </Typography>
                      </CardActionArea>
            
                      <Typography variant="subtitle2" gutterBottom color="textSecondary" component="p">
                          {store.store_brief}
                      </Typography>                   
            
                    </CardContent>
                  </Card>
                  ))
                }
              </GridContainer>
              <div className={classes.title}>
                <Divider variant="middle" />  
                <Col sm="4">
                  <Link to="/createstore">
                    <Button block color="info" type="button" >
                        Create New Store
                    </Button>
                  </Link>
                </Col>
              </div>
              </>
            : <TransitionAlert message="Please Log In to access your stores list or to create a new store.
                                        Or you need to be registered as a Seller."
                               severity="info"  />
          }



        <div className={classes.space50} />
        </div>
      </div>
    </div>
  );
}
