import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { apiUrl, jwSToken} from "config.js";
import CategoryChooser from "components/FiltersChoosers/CategoryChooser.js";
import ShowMap from "components/Maps/GoogleMap.js";
import MapChooser from "components/FiltersChoosers/MapChooser.js";
import MediaChooser from "components/FiltersChoosers/MediaChooser.js";
import MediaCarousel from "components/FiltersChoosers/MediaCarousel.js";
import Terms from "components/Terms/TermsOfUse.js";
import Editor from "components/Editor/Editor.simple.js";

import { CategoryContext, MapContext, MediaContext, currentUserEmail } from "_components";
// import { CloudinaryContext } from "cloudinary-react";

// UI
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TransitionAlert from "components/Alerts/TransitionAlert.js";
import Success from "components/Typography/Success.js";
import Danger from "components/Typography/Danger.js";

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input'
import labels from 'react-phone-number-input/locale/en.json';
import "bootstrap/dist/css/bootstrap.css";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';

import styles from "assets/jss/material-kit-react/views/siteEditPages.js";
import srcimage from "../../assets/img/food2.jpg";

const useStyles = makeStyles(styles);
const divStyle = {
  margin: "15px 5px 5px 5px",
  border: "2px blue",
}

const EditStore = props => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isAgreeToTermsAndConditionsChecked, setIsAgreeToTermsAndConditionsChecked] = useState(false);
    const [terms, showTerms] = useState(false);
    const [isDone, setDone] = useState(false);
    const [showEditLoc, setShowEditLoc] = useState(false);

    const [storename, setstorename] = useState(undefined);
    const [storebrief, setstorebrief] = useState(undefined);
    const [descshort, setdescshort] = useState(undefined);
    const [details, setdetails] = useState(" ");
    const [subsitelink, setsubsitelink] = useState(undefined);
      
    const [contactemail, setcontactemail] = useState(undefined);
    const [contactnumber1, setcontactnumber1] = useState(undefined);
    const [contactnumber2, setcontactnumber2] = useState("0");
    const [contactnumber3, setcontactnumber3] = useState("0");
    const [lat, setLat] = useState();
    const [lng, setLng] = useState();

    const [initialCats, setInitialCats] = useState([]);
    // const [initialCoords, setInitialCoords] = useState({});

    const [facebook, setFacebook] = useState("");
    const [instagram, setInstagram] = useState("");
    const [twitter, setTwitter] = useState("");
    const [youtube, setYoutube] = useState("");
    const [website, setWebsite] = useState("");

    const [cat, setCat] = useContext(CategoryContext);
    const [mymap, setMymap] = useContext(MapContext);
    const [media, setMedia] = useContext(MediaContext);

    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    setTimeout(function() {
      setCardAnimation("");
    }, 700);
    const classes = useStyles();

    const axiosHeader = {
      headers: { Authorization: `Bearer ${jwSToken}` }
    };

   
    // /////////////////////////////////////
    // data Manipulation from the API
    const { match } = props;
    let {subsite_link} = match.params;
    const filter = (subsite_link) ? "?subsite_link=eq." + subsite_link : " ";
    let newdetails = "";

    const [storefeed, handlestorefeed] = useState([]);  
    // const [mediafeed, handlemediafeed] = useState([]);  
    useEffect(() => {
        FetchData() //, FetchMedia()
    }, [filter]);

    const FetchData = async() => {
        const data = await axios.get(
            `${apiUrl}/edit_sellers_vw${filter}`,
            axiosHeader
          );
        const storefeed = data.data;
        handlestorefeed(storefeed);
        const storeinfo = (storefeed[0]) || [];

        setLat(storeinfo.lat);
        setLng(storeinfo.lng);
        setstorename(storeinfo.store_name);
        setstorebrief(storeinfo.store_brief );
        setdescshort(storeinfo.description_short );
        setdetails(storeinfo.details );
        setsubsitelink(storeinfo.subsite_link );
        setcontactemail(storeinfo.email );
        setcontactnumber1(storeinfo.contact_info_1);
        setcontactnumber2(storeinfo.contact_info_2);
        setcontactnumber3(storeinfo.contact_info_3);
        setFacebook(storeinfo.facebook );
        setInstagram(storeinfo.instagram );
        setTwitter(storeinfo.twitter );
        setYoutube(storeinfo.youtube );
        setWebsite(storeinfo.website );
        setInitialCats(storeinfo.categories);
    }
    // END fetch
    ////////////////////////////////////////////

    function handleSubmit(e) {
      e.preventDefault();
      setIsSubmitted(true);
      let newdetails = localStorage.getItem('content');         // Getting DraftJS Content from localSTorage as Editor saves to that location.  Easier for now.
      // alert(newdetails)
      const isFormValid = e.target.checkValidity()  && isValidPhoneNumber(contactnumber1);
      !isValidPhoneNumber(contactnumber1) && alert("Invalid Phone Number Format. ");
      // alert("Handle Submit: " + isFormValid + isAgreeToTermsAndConditionsChecked );
      if (isFormValid && isAgreeToTermsAndConditionsChecked) {
        // const recaptchaValue = recaptchaRef.current.getValue();
        // this.props.handleSubmit(recaptchaValue);
        postStore(newdetails);
      } else {
        // Form has errors, so don't create the account
        alert("Form has errors. Retry again. ");
        // return <TransitionAlert severity="error" message="Form has errors. Retry again." /> && setDone(true);
      }
    }; // END HANDLE SUBMIT
    
    function postStore(newdetails) {
      axios.post(
        `${apiUrl}/rpc/vmeditstore`, 
        {   "vemail": currentUserEmail(),
            "vstorebrief": storebrief,
            "vdescshort": descshort,
            "vdetails": newdetails,
            "vsubsitelink": subsitelink,
            "vcontactemail": contactemail,
            "vcontactnumber1": contactnumber1,
            "vcontactnumber2": contactnumber2,
            "vcontactnumber3": contactnumber3,
            "vfacebook": facebook,
            "vinstagram": instagram,
            "vtwitter": twitter,
            "vyoutube": youtube,
            "vwebsite": website,
            "vlat": mymap.lat || lat,
            "vlng": mymap.lng || lng,
            "vcats": cat,
            "vmedia": media
        },
        axiosHeader
      ).then(result => {
        // alert( JSON.stringify(result) );
        if (result.status === 201 || result.status === 200) {
          setIsError(false);
          setDone(true);
          alert("Your changes are effective immediately.");
        } else if (result.status === 401 ) {
          setIsError(true);
          setDone(true);
          alert("Unauthorized Access");
        } else if (result.status === 409 ) {
          setIsError(false);
          setDone(true);
          alert(" Error 409. Contact support@villagemarket.ph for issue.");
        } else {
          setIsError(true);
          // console.log(JSON.stringify(result.message) );
        }
      })
      .catch(e => {
        alert("CATCH ERROR: Data RPC Post issue ");
        // console.log(JSON.stringify(e) );                                            // REMOVE IN PRODUCTION
        setIsError(true);
      })
      ;
    };

    if (isDone) {
      return <Redirect to="/"/>;
    };

    if (isError) {
      return <TransitionAlert severity="error" message="There was an error. " />
    };
    
  return (
  <div
    style={{
      backgroundImage: "url(" + srcimage + ")",
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "100vh",
    }}
  >
  <GridContainer justify="center">
    <GridItem xs={12} sm={10} md={10} lg={8}>
    <Card className={classes[cardAnimaton]}>    
        {/* Store Name */}
        <div className="form-group">
          <div className="form-row">  
              <div className="col">
                <TextField
                  type="text"
                  className="form-control form-control-sm"
                  id="storenameInput"
                  value={storename}
                  helperText="Store Name. Cannot change as this is a key field."
                  variant="outlined"
                  size="small"
                  disabled
                />
              </div>

              <div className="col">
                <TextField
                  type="subsitelink"
                  className="form-control form-control-sm"
                  id="subsitelinkInput"
                  value={subsite_link}
                  helperText="Site Link. Cannot change as this is a key field."
                  variant="outlined"
                  size="small"
                  disabled
                />
              </div>
        </div>
        <div className="form-row">
          <Divider variant="middle" className="divider" />
        </div>
      </div>
      {/* END Store Name */}
      
        <form className={`p-4 ${'shadow-sm rounded-sm bg-white'} ${isSubmitted ? 'was-validated' : ''}`} noValidate onSubmit={handleSubmit}>
          <div className="form-group">
          
            {/* Store Brief */}
            <div className="form-row">  
            {/* <label htmlFor="storebriefInput" className={classes.title}>Store Brief</label> */}
              <div className="col">
                <Typography variant="subtitle2" gutterBottom>
                  Store Brief
                </Typography>
                <TextField
                  id="storebriefInput"
                  value={storebrief}
                  multiline
                  rowsMax={5}
                  fullWidth
                  margin="normal"
                  onChange={(e) => setstorebrief(e.target.value)}
                  helperText="Enter a brief description or tagline. Shows below Store Name.  (200 char. of plain text and 5 rows max)"
                  size="small"
                  required
                />
              </div>
            </div>  {/* END Store Brief */}

            {/* Store Desc */}
            <div className="form-row">  
            {/* <label htmlFor="descshortInput" className={classes.title}>Description</label> */}
              <div className="col">
                <Typography variant="subtitle2" gutterBottom>
                  Description
                </Typography>
                <TextField
                  id="descshortInput"
                  value={descshort}
                  multiline
                  rowsMax={10}
                  fullWidth
                  margin="normal"
                  onChange={(e) => setdescshort(e.target.value)}
                  helperText="Shows in the Introduction. Include products sold, prices and any promotions.  (500 char. of plain text and 10 rows max)"
                  size="small"
                  required
                />
              </div>
            </div>  {/* END Store Desc */}

            {/* Store Details */}
            <div className="form-row">  
              <div className="col">
                { (details.length > 1) && <Editor details={details}/> }
              </div>
            </div>  {/* END Store Details */}
          
            <Divider variant="middle" className="divider" />
            {/* Store Contact Nums */}
            <div className="form-row">  
              <div className="col">
                <label htmlFor="contactemailInput">Contact Email *</label>
                <input
                  onChange={(e) => setcontactemail(e.target.value)}
                  type="contactemail"
                  className="form-control form-control-sm"
                  id="contactemailInput"
                  pattern = "^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                  value={contactemail}
                  aria-describedby="emailHelp"
                  required
                />
                <div className="invalid-feedback">
                  Please enter a contact email in the correct format.
                </div>
              </div>

              <div className="col">
                <label htmlFor="primaryCellInput1">Contact number (1) * </label>
                <PhoneInput 
                  // onChange={(e) => setcontactnumber1(e.target.value)}
                  onChange = { setcontactnumber1 }
                  defaultCountry="PH"
                  className="form-control form-control-sm"
                  labels={labels}
                  value={contactnumber1}
                  id="primaryCellInput1"
                  required
                />
                <div className="invalid-feedback">
                  Enter your contact number in a valid format.
                </div>
              </div>
            </div>  {/* END Store Contact Nums */}


            {/* Store Contact Nums 2 */}
            <div className="form-row">  
              <div className="col">
                <label htmlFor="primaryCellInput2">Contact number (2) </label>
                <PhoneInput 
                  onChange = {() => setcontactnumber2(!!contactnumber2 ? " " : contactnumber2) }
                  defaultCountry="PH"
                  className="form-control form-control-sm"
                  labels={labels}
                  value={contactnumber2}
                  id="primaryCellInput2"
                />
                <div className="invalid-feedback">
                  Enter your contact number in a valid format.
                </div>
              </div>

              <div className="col">
                <label htmlFor="primaryCellInput3">Contact number (3) </label>
                <PhoneInput 
                  onChange = {() => setcontactnumber3(!!contactnumber3 ? " " : contactnumber3) }
                  defaultCountry="PH"
                  className="form-control form-control-sm"
                  labels={labels}
                  value={contactnumber3}
                  id="primaryCellInput3"
                />
                <div className="invalid-feedback">
                  Enter your contact number in a valid format.
                </div>
              </div>
            </div>  {/* END Store Contact Nums2 */}

            <Divider variant="middle" className="divider"/>
            {/* Social Media */}
            <div className="form-row">  
            <div className="col">
                <label htmlFor="facebooklinkInput"> Facebook Page </label>
                <input
                  onChange={(e) => setFacebook(e.target.value)}
                  pattern="[-a-zA-Z0-9@_.]{1,30}"
                  type="facebooklink"
                  className="form-control form-control-sm"
                  value={facebook}
                  id="facebooklinkInput"
                />
                <div className="invalid-feedback">
                  Enter Valid Facebook name.
                </div>
              </div>

              <div className="col">
                <label htmlFor="twitterlinkInput"> Twitter ID </label>
                <input
                  onChange={(e) => setTwitter(e.target.value)}
                  pattern="[-a-zA-Z0-9@_.]{1,30}"
                  type="twitterlink"
                  className="form-control form-control-sm"
                  value={twitter}
                  id="twitterlinkInput"
                />
                <div className="invalid-feedback">
                  Enter Valid Twitter ID.
                </div>
              </div>
              
            </div>  {/* END Social Media */}

            {/* Social Media 2*/}
            <div className="form-row">  
            <div className="col">
                <label htmlFor="instagramlinkInput"> Instagram ID </label>
                <input
                  onChange={(e) => setInstagram(e.target.value)}
                  pattern="[-a-zA-Z0-9@_.]{1,30}"
                  type="instagramlink"
                  className="form-control form-control-sm"
                  value={instagram}
                  id="instagramlinkInput"
                />
                <div className="invalid-feedback">
                  Enter Valid Instagram ID.
                </div>
              </div>

              <div className="col">
                <label htmlFor="youtubelinkInput"> Youtube Link </label>
                <input
                  onChange={(e) => setYoutube(e.target.value)}
                  // pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)"
                  type="youtubelink"
                  className="form-control form-control-sm"
                  value={youtube}
                  id="youtubelinkInput"
                />
                <div className="invalid-feedback">
                  Enter Valid URL.
                </div>
              </div>

              <div className="col">
                <label htmlFor="websiteInput"> Website Link </label>
                <input
                  onChange={(e) => setWebsite(e.target.value)}
                  pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)"
                  type="websitelink"
                  className="form-control form-control-sm"
                  value={website}
                  id="websiteInput"
                />
                <div className="invalid-feedback">
                  Enter Valid URL.
                </div>
              </div>
            </div>  {/* END Social Media 2*/}

            <Divider variant="middle" className="divider"/>

            {/* Category Chooser*/}
            <div className="form-row">  
                <CategoryChooser initialCats={initialCats}/>
            </div>  {/* END Category Chooser */}

            <Divider variant="middle" className="divider"/>
      
            {/* Map Chooser*/}
            <Typography variant="subtitle2" gutterBottom style={divStyle}>
                Current Store Location
            </Typography>
            <div className="form-row">  
                { showEditLoc
                  ?  <MapChooser />
                  :  !!(lat + lng) && <ShowMap lat={lat} lng={lng} name={storename} />  
                }
            </div>  
            <Button onClick={() => ( showEditLoc ? setShowEditLoc(false) :setShowEditLoc(true) ) } 
                    variant="contained" 
                    className={classes.buttonStyle}
                    color="primary"
            > 
                Edit/Show Location 
            </Button>
            
            {/* END Map Chooser */}

            <Divider variant="middle" className="divider"/>
            <Typography variant="subtitle2" gutterBottom style={divStyle}>
                Site Images
            </Typography>
            {/* Media Carousel*/}
            <div className="form-row">  
                <MediaCarousel subsite_link={subsite_link} />
            </div>  {/* END Media Carousel */}


            <Divider variant="middle" className="divider"/>
            <Success className="divider"> Upload up to 10 pictures.  Limit image sizes to 10MB each. Media changes take a while to take effect across the cache servers. </Success>
            {/* Media Chooser*/}
            <div className="form-row">  
                <MediaChooser />
            </div>  {/* END Media Chooser */}

          <Divider variant="middle" className="divider"/>
          <div className="form-group form-check">
            <FormControlLabel
              control={<Checkbox onChange={(e) => setIsAgreeToTermsAndConditionsChecked(e.target.checked)} id="agreeToTermsAndConditionsCheckbox" />}
            />
            I agree to the {' '}
            <Link href="#" onClick={() => showTerms(true)}>
               Terms and Conditions
            </Link>
            { terms ? <Terms/> : ""}
          </div>
 
          <button type="submit" className="btn btn-primary btn-block btn-sm">Save Changes</button>
          <Success>
             NOTE: To publish you have to click on the link in the validation email.  You will receive an email everytime you save changes. 
          </Success>
          <Success>
             Only validate when you are ready for the world to see your changes:-)
          </Success>
          <Danger>
             NOTE: To delete a site, please email support@villagemarket.ph with your registered email.  We will validate the SITE deletion with your registered contact number.
          </Danger>

          <div className="d-flex justify-content-between mt-4">
            <Link to="/managestores">
              <button type="button" className="btn btn-link btn-sm p-0">&#8592; Manage Store(s) </button>
            </Link>
            <Link to="/">
              <button type="button" className="btn btn-link btn-sm p-0"> Home </button>
            </Link>
          </div>

          </div>
        </form>
      </Card>
    </GridItem>
  </GridContainer>
  </div>
  );
};

EditStore.propTypes = {
  backgroundColor: PropTypes.string,
  isFlat: PropTypes.bool,
  heading: PropTypes.string,
  headingColor: PropTypes.string,
  subheading: PropTypes.string,
  subheadingColor: PropTypes.string,
  image: PropTypes.node,
};

EditStore.defaultProps = {
  backgroundColor: 'white',
  isFlat: false,
  heading: '',
  headingColor: 'black',
  subheading: '',
  subheadingColor: 'black',
  image: undefined,
};

export default EditStore;
