import { container, title } from "assets/jss/material-kit-react.js";

import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const sitesPagesStyle = {
  container,
  ...imagesStyle,
  header: {
    marginLeft: "50px",
    marginRight: "50px",
    alignContent: "top",
  },
  mediacontainer: {
    height: 300,
    width: "80%",    
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
  },
  media: {
    height: 400,
    margin: "auto",
    width: "80%", 
  },
  section: {
    padding: "70px 0"
  },
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important"
  },
  main: {
    background: "linear-gradient(90deg, #3dadc0 30%, #888844 90%)",
    height: "100vh",
    position: "relative",
    zIndex: "10",
    justifyContent: "center",
    alignContent: "center",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  root: {
    // display: "flex",
    // flexWrap: "wrap",
    maxWidth: "80%",
    justifyContent: "left",
    alignContent: "top",
    background: "rgba(253, 249, 227, 0.9)",
  },
  profile: {
    textAlign: "center",
    "& img": {
      maxWidth: "160px",
      width: "100%",
      margin: "0 auto",
      transform: "translate3d(0, -50%, 0)"
    }
  },
  description: {
    margin: "1.071rem auto 0",
    maxWidth: "600px",
    color: "#999",
    textAlign: "center !important"
  },
  name: {
    marginTop: "-80px"
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "20px",
    minHeight: "32px",
    textDecoration: "none"
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999"
  },
  navWrapper: {
    margin: "20px auto 50px auto",
    textAlign: "center"
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  progress: {
    background: "rgba(0,0,0,0)",
    width: "100%",
  },
  divider: {
    display: "flex",
    marginTop: "10px",
    margin: "10px 0px 10px 0px",
    lineHeight: "5px",
  },
  buttonStyle: {
    margin: "5px 5px",
  }
};

export default sitesPagesStyle;
