import React, { useState} from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

const EditMap = (props) => {
  const [isMarkerShown, setMarkerShown] = useState(false); 
  const [loc, setLoc] = useState({lat: 14.6337, lng: 121.0559})  
   
  const onMapClick = (e) => {
    setMarkerShown(true);
    setLoc({lat: e.latLng.lat(), lng: e.latLng.lng()});
   };

  const MyMapComponent = withScriptjs(
    withGoogleMap(props => (
      <GoogleMap
        defaultZoom={13}
        defaultCenter={{ lat: props.lat, lng: props.lng }}
        onClick={props.onClick}
      >
            {alert(props.lat)}
            { isMarkerShown
                ? <Marker position={{ lat: props.lat, lng: props.lng }} />
                : null
            }
      </GoogleMap>
    ))
  );

  return (
    <div className="container">
      <div className="map">
        <MyMapComponent
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyABOu2v7txJ83jlYv7SutPUO77HHocrZkU&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          lat={loc.lat}
          lng={loc.lng}
          onClick={onMapClick}      // You need this in outer call https://stackoverflow.com/questions/54366513/react-google-maps-is-refreshing-the-map-when-onclick-is-performed
        />
      </div>
    </div>
  );
};

export default EditMap;