import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { apiUrl, jwAToken, jwSToken } from "config.js";
import Terms from "components/Terms/TermsOfUse.js"
import SocialMediaNew from "./SocialMediaNewUser.js";
// import ReCAPTCHA from "react-google-recaptcha";

// UI
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import { KeyboardDatePicker } from "@material-ui/pickers";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input'
import labels from 'react-phone-number-input/locale/en.json';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import "bootstrap/dist/css/bootstrap.css";
import TransitionAlert from "components/Alerts/TransitionAlert.js";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Typography from '@material-ui/core/Typography';

import styles from "assets/jss/material-kit-react/views/loginPage.js";
import srcimage from "../../assets/img/food2.jpg";
const useStyles = makeStyles(styles);

const CreateAccountForm = ({
  backgroundColor, heading, subheading, headingColor, subheadingColor, isFlat, image,
}) => {
  const [terms, showTerms] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isAgreeToTermsAndConditionsChecked, setIsAgreeToTermsAndConditionsChecked] = useState(false);
  const [isDone, setDone] = useState(false);
  const [firstName, setFirstName] = useState(undefined);
  const [lastName, setLastName] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [password, setPassword] = useState(undefined);
  // const [dob, setDob] = useState(null);
  const [role, setRole] = useState("Buyer");
  const [cellnumber, setCellnumber] = useState(" ");
  const [secondaryumber, setSecondarynumber] = useState("0");

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();

  const axiosHeader = {
    headers: { Authorization: `Bearer ${jwAToken}` }
  };

  // const axiosSHeader = {
  //   headers: { Authorization: `Bearer ${jwSToken}` }
  // };

  // const recaptchaRef = React.createRef();

  // function onChange(value) {
  //   console.log("Captcha value:", value);
  // }

  function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitted(true);
    const isFormValid = e.target.checkValidity()  && isValidPhoneNumber(cellnumber);
    !isValidPhoneNumber(cellnumber) && alert("Invalid Phone Number Format. ");
    if (isFormValid && isAgreeToTermsAndConditionsChecked) {
      // const recaptchaValue = recaptchaRef.current.getValue();
      // this.props.handleSubmit(recaptchaValue);
      postUsers();
    } else {
      // Form has errors, so don't create the account
      alert("Form has errors. Retry again. ");
      // return <TransitionAlert severity="error" message="Form has errors. Retry again." /> && setDone(true);
    }
  };

  function sendEmail() {
    let mailadd = `https://villager.pythonanywhere.com/api/v1/validate?user=${email}`
    axios.get(mailadd)
         .then(result => {
            if (result.status === 201 || result.status === 200) {
                  alert("You will receive an email to validate your account. v.01. " );
            } 
         })
        .catch(e => {
          // alert("CATCH ERROR: Validate Post issue.  ");
          console.log(JSON.stringify(e) );
        })
  }

  function postUsers() {
    axios.post(
      `${apiUrl}/rpc/vmsignup`, 
      {   "email": email,
          "pass": password,
          "fname": firstName,
          "lname": lastName,
          "vmrole": role,
          "user_bday": null,
          "cellnumber": cellnumber,
          "secondarynumber": secondaryumber
      },
      axiosHeader
    ).then(result => {
      // alert( JSON.stringify(result) );
      if (result.status === 201 || result.status === 200) {
        setIsError(false);
        setDone(true);
        // alert("You will receive an email to validate your account.");
        sendEmail();
      } else if (result.status === 401 ) {
        setIsError(false);
        setDone(true);
        alert("Unauthorized Access");
      } else if (result.status === 409 ) {
        setIsError(false);
        setDone(true);
        alert("Email already exists. Choose 'Forgot Password' to reset password or email info@villagemarket.ph for issue.");
      } else {
        setIsError(true);
        // alert( JSON.stringify(result.message) );
      }
    })
    .catch(e => {
      alert("CATCH ERROR: Data RPC Post issue ");
      // alert(JSON.stringify(e) );
      setIsError(true);
    })
    ;
  };

  if (isDone) {
    return <Redirect to="/"/>;
  };

  if (isError) {
    return <TransitionAlert severity="error" message="There was an error. " />
  };
    
  return (
  <div
    style={{
      backgroundImage: "url(" + srcimage + ")",
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "100vh",
    }}
  >
  <GridContainer justify="center">
    <GridItem xs={12} sm={10} md={10} lg={8}>
    <Card className={classes[cardAnimaton]}>

        <form className={`p-4 ${isFlat ? '' : 'shadow-sm rounded-sm bg-white'} ${isSubmitted ? 'was-validated' : ''}`} noValidate onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="form-row">
              <div className="col">
                <label htmlFor="firstNameInput">First Name*</label>
                <input
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                  className="form-control form-control-sm"
                  id="firstNameInput"
                  value={firstName}
                  required
                />
                <div className="invalid-feedback">
                  Enter your first name.
                </div>
              </div>
              <div className="col">
                <label htmlFor="lastNameInput">Last Name*</label>
                <input
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  className="form-control form-control-sm"
                  id="lastNameInput"
                  value={lastName}
                  required
                />
                <div className="invalid-feedback">
                  Enter your last name.
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="emailInput">Email Address*</label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              className="form-control form-control-sm"
              id="emailInput"
              value={email}
              aria-describedby="emailHelp"
              required
            />
            <div className="invalid-feedback">
              Please enter an email address in the correct format.
            </div>
          </div>

          <div className="form-group">
            <div className="form-row">
              <div className="col">
                <label htmlFor="passwordInput">Password * </label>
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  type="password"
                  className="form-control form-control-sm"
                  id="passwordInput"
                  required
                />
                <div className="invalid-feedback">
                  Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters
                </div>
              </div>
              <div className="col">
                <label htmlFor="confirmPasswordInput">Confirm Password</label>
                <input
                  pattern={`${password}`}
                  type="password"
                  className="form-control form-control-sm"
                  id="confirmPasswordInput"
                  required
                />
                <div className="invalid-feedback">
                  Please make sure that your passwords match.
                </div>
              </div>
            </div>
          </div>
          {/* END of PASSWORD GROUP */}

          <div className="form-group">
            <div className="form-row">

              {/* <div className="col">
                <KeyboardDatePicker
                  onChange={setDob}
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="Date of Birth. Used for Validation"
		              format="yyyy/MM/dd"
                  value={dob}
                  InputAdornmentProps={{ position: "start" }}
                  required
                />
                <div className="invalid-feedback">
                  Enter your Birth Date.  Used for future validation. 
                </div>
              </div> */}

              <div className="col">
                <label htmlFor="roleInput"> Role </label>
                <Select
                  onChange={(e) => setRole(e.target.value)}
                  pattern="Buyer|Seller"
                  type="text"
                  defaultValue="Buyer"
                  className="form-control form-control-sm"
                  id="roleInput"
                  value={role}
                  required
                >
                  <MenuItem value="Buyer">Buyer</MenuItem>
                  <MenuItem value="Seller">Seller</MenuItem>
                </Select>
                <br/>
                <div className="notestext">
                    Buyer:  A user who can save favorites and receive weekly emails/updates on changes to favorite stores.
                    Seller:  Like a buyer but can also create stores which will be posted on this site.
                </div>  
                <div className="invalid-feedback">
                   Choose Role.
                </div>
              </div>
            </div>
          </div>
          {/* END of OTHER INFO GROUP */}

          <div className="form-group">
            <div className="form-row">
              <div className="col">
                <label htmlFor="primaryCellInput">Cellphone number for Messages</label>
                <PhoneInput 
                  // onChange={(e) => setCellnumber(e.target.value)}
                  onChange = { setCellnumber }
                  defaultCountry="PH"
                  className="form-control form-control-sm"
                  labels={labels}
                  id="primaryCellInput"
                  // required
                />
                <div className="invalid-feedback">
                  Enter your cellphone number in valid format.
                </div>
              </div>
              <div className="col">
                <label htmlFor="secondaryContactInput">Secondary Number</label>
                <input
                  onChange={(e) => setSecondarynumber(e.target.value)}
                  type="text"
                  className="form-control form-control-sm"
                  id="secondaryContactInput"
                />
              </div>
            </div>
          </div>
          {/* END of PHONE GROUP */}


          <div className="form-group form-check">
            <FormControlLabel
              control={<Checkbox onChange={(e) => setIsAgreeToTermsAndConditionsChecked(e.target.checked)} id="agreeToTermsAndConditionsCheckbox" />}
            />
             I agree to the {' '}
            <Link href="#" onClick={() => showTerms(true)}>
               Terms and Conditions
            </Link>
            { terms ? <Terms/> : ""}
          </div>
 
          {/* <div className="d-flex justify-content-center">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LcOt_wUAAAAAAsf72Ahs4-0flEsyTf_Pck2Dt7f"
              onChange={onChange}
            />  
          </div> */}
          <br/>

          <button type="submit" className="btn btn-primary btn-block btn-sm">Create Account</button>

          <SocialMediaNew greeting="Signup"/>

          <div className="d-flex justify-content-between mt-4">
            <Link to="/login-page">
              <button type="button" className="btn btn-link btn-sm p-0">&#8592; Log In</button>
            </Link>
            <Link to="/forgotpass">
              <button type="button" className="btn btn-link btn-sm p-0">Forgot Password?</button>
            </Link>
          </div>

        </form>
      </Card>
      </GridItem>
  </GridContainer>
  </div>
  );
};

CreateAccountForm.propTypes = {
  backgroundColor: PropTypes.string,
  isFlat: PropTypes.bool,
  heading: PropTypes.string,
  headingColor: PropTypes.string,
  subheading: PropTypes.string,
  subheadingColor: PropTypes.string,
  image: PropTypes.node,
};

CreateAccountForm.defaultProps = {
  backgroundColor: 'white',
  isFlat: false,
  heading: '',
  headingColor: 'black',
  subheading: '',
  subheadingColor: 'black',
  image: undefined,
};

export default CreateAccountForm;
