import React, { useState, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { apiUrl, jwSToken } from "config.js";
// import ReCAPTCHA from "react-google-recaptcha";
import CategoryChooser from "components/FiltersChoosers/CategoryChooser.js";
import MapChooser from "components/FiltersChoosers/MapChooser.js";
import MediaChooser from "components/FiltersChoosers/MediaChooser.js";
import { CategoryContext, MapContext, MediaContext, currentUserEmail } from "_components";
// import { CloudinaryContext } from "cloudinary-react";
import Terms from "components/Terms/TermsOfUse.js"
import Editor from "components/Editor/Editor.initial.js";

// UI
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TransitionAlert from "components/Alerts/TransitionAlert.js";
import Success from "components/Typography/Success.js";

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input'
import labels from 'react-phone-number-input/locale/en.json';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
import "bootstrap/dist/css/bootstrap.css";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

import styles from "assets/jss/material-kit-react/views/siteEditPages.js";
import srcimage from "../../assets/img/food2.jpg";

const useStyles = makeStyles(styles);

const CreateStore = ({
  backgroundColor, heading, subheading, headingColor, subheadingColor, isFlat, image,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [terms, showTerms] = useState(false);
  const [isAgreeToTermsAndConditionsChecked, setIsAgreeToTermsAndConditionsChecked] = useState(false);
  const [isDone, setDone] = useState(false);
  
  const [storename, setstorename] = useState(undefined);
  const [storebrief, setstorebrief] = useState(undefined);
  const [descshort, setdescshort] = useState(undefined);
  const [details, setdetails] = useState(" ");
    
  const [contactemail, setcontactemail] = useState(undefined);
  const [contactnumber1, setcontactnumber1] = useState(undefined);
  const [contactnumber2, setcontactnumber2] = useState("0");
  const [contactnumber3, setcontactnumber3] = useState("0");

  const [facebook, setFacebook] = useState(" ");
  const [instagram, setInstagram] = useState(" ");
  const [twitter, setTwitter] = useState(" ");
  const [youtube, setYoutube] = useState(" ");
  const [website, setWebsite] = useState(" ");

  const [subsitelink, setsubsitelink] = useState(undefined);
  const [subsites, handleSubsites] = useState();
  const subsiteRef = useRef();

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();

  const axiosHeader = {
    headers: { Authorization: `Bearer ${jwSToken}` }
  };

  // const recaptchaRef = React.createRef();

  // function onChange(value) {
  //   console.log("Captcha value:", value);
  // }

  const [cat, setCat] = useContext(CategoryContext);
  const [mymap, setMymap] = useContext(MapContext);
  const [media, setMedia] = useContext(MediaContext);

  function handleSubmit(e) {
    let newdetails = localStorage.getItem('content');         // Getting DraftJS Content from localSTorage as Editor saves to that location.  Easier for now.
    e.preventDefault();
    setIsSubmitted(true);
    const isFormValid = e.target.checkValidity()  && isValidPhoneNumber(contactnumber1);
    !isValidPhoneNumber(contactnumber1) && alert("Invalid Phone Number Format. ");
    // alert("Handle Submit: " + isFormValid + isAgreeToTermsAndConditionsChecked );
    if (isFormValid && isAgreeToTermsAndConditionsChecked) {
      // const recaptchaValue = recaptchaRef.current.getValue();
      // this.props.handleSubmit(recaptchaValue);
      postUsers(newdetails);
    } else {
      // Form has errors, so don't create the account
      alert("Form has errors. Retry again. ");
      // return <TransitionAlert severity="error" message="Form has errors. Retry again." /> && setDone(true);
    }
  };

  function sendEmail(user, site) {
    let mailadd = `https://villager.pythonanywhere.com/api/v1/publish?user=${user}&store=${site}`
    axios.get(mailadd)
         .then(result => {
            if (result.status === 201 || result.status === 200) {
                  alert("You will receive an email to publish your store. v.01. " );
            } 
         })
        .catch(e => {
          // alert("CATCH ERROR: Validate Post issue.  ");
          console.log(JSON.stringify(e) );
        })
  }

  function postUsers(newdetails) {
    axios.post(
      `${apiUrl}/rpc/vmcreatestore`, 
      {   "vemail": currentUserEmail(),
          "vstorename": storename,
          "vstorebrief": storebrief,
          "vdescshort": descshort,
          "vdetails": newdetails,
          "vsubsitelink": subsitelink,
          "vcontactemail": contactemail,
          "vcontactnumber1": contactnumber1,
          "vcontactnumber2": contactnumber2,
          "vcontactnumber3": contactnumber3,
          "vfacebook": facebook,
          "vinstagram": instagram,
          "vtwitter": twitter,
          "vyoutube": youtube,
          "vwebsite": website,
          "vlat": mymap.lat,
          "vlng": mymap.lng,
          "vcats": cat,
          "vmedia": media || "https://res.cloudinary.com/villagemarket/image/upload/v1601723915/vm/banig_x2uify.jpg"
      },
      axiosHeader
    ).then(result => {
      // alert( JSON.stringify(result) );
      if (result.status === 201 || result.status === 200) {
        setIsError(false);
        setDone(true);
        alert("You will receive an Email to validate your changes. Confirm to publish your changes");
        sendEmail(currentUserEmail(), subsitelink)
      } else if (result.status === 401 ) {
        setIsError(false);
        setDone(true);
        alert("Unauthorized Access");
      } else if (result.status === 409 ) {
        setIsError(false);
        setDone(true);
        alert(" Error 409. Contact info@villagemarket.ph for issue.");
      } else {
        setIsError(true);
        // console.log( JSON.stringify(result.message) );
        alert(" Error 409. Contact info@villagemarket.ph for issue.");
      }
    })
    .catch(e => {
      alert("CATCH ERROR: Data RPC Post issue ");
      // alert(JSON.stringify(e) );
      setIsError(true);
    })
    ;
  }; // END postUser


  // //////////////////////////////////////////////////////////////////////////////
  // Section to load subsites for checking form so that subsite name 
  // is not used 2x.  Informed as soon as possible instead of during form submit
  useEffect(() => {
    loadSubsites()
  }, [])  // triggers loading of subsites ONLY on 1st render

  const loadSubsites = async () => {
    const data = await axios.get(
      `${apiUrl}/subsites_vw`,
      axiosHeader
    );
    const vsubsitesObj = data.data;
    let vsubsites = vsubsitesObj.map(ss => { return ss.subsite_link  })
    handleSubsites(vsubsites);
  }

  function checkSubsite(e) {
    if (subsites.includes(e.target.value)) {
      alert("Subsite Link already exists.  Choose another subsite name.");
      subsiteRef.current.value = null;
      subsiteRef.current.focus();
    }
  } 
  // END checkSubsite SECTION

  if (isDone) {
    return <Redirect to="/"/>;
  };

  if (isError) {
    return <TransitionAlert severity="error" message="There was an error. Contact support@villagemarket.ph" />
  };
    
  return (
  <div
    style={{
      backgroundImage: "url(" + srcimage + ")",
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "100vh",
    }}
  >
  <GridContainer justify="center">
    <GridItem xs={12} sm={10} md={10} lg={8}>
      <Card className={classes[cardAnimaton]}>
        <form className={`p-4 ${isFlat ? '' : 'shadow-sm rounded-sm bg-white'} ${isSubmitted ? 'was-validated' : ''}`} noValidate onSubmit={handleSubmit}>
          <div className="form-group">

        {/* Store Name */}
        <div className="form-row">  
              <div className="col">
                <label htmlFor="storenameInput">Store Name*</label>
                <input
                  onChange={(e) => setstorename(e.target.value)}
                  type="text"
                  className="form-control form-control-sm"
                  id="storenameInput"
                  value={storename}
                  required
                />
                <div className="invalid-feedback">
                  Enter your store name.
                </div>
              </div>

              <div className="col">
                <label htmlFor="subsitelinkInput">Subsite Link* </label>
                <input
                  onChange={(e) => setsubsitelink(e.target.value)}
                  onBlur={(e)=>checkSubsite(e)}
                  ref={subsiteRef}
                  pattern="^[a-z0-9_]{3,20}$"
                  type="text"
                  className="form-control form-control-sm"
                  id="subsitelinkInput"
                  required
                />
                <div className="invalid-feedback">
                  Must not contain spaces and other invalid characters. 3-20 characters.
                </div>
              </div>
            </div>  {/* END Store Name */}
         
            {/* Store Brief */}
            <div className="form-row">  
              <div className="col">
                <TextField
                  id="storebriefInput"
                  value={storebrief}
                  label="Store Brief"
                  placeholder="Enter brief description or tagline."
                  multiline
                  rowsMax={5}
                  fullWidth
                  margin="normal"
                  onChange={(e) => setstorebrief(e.target.value)}
                  helperText="Enter a brief description or tagline. Shows below Store Name.  (200 char. and 5 rows max)"
                  required
                />
              </div>
            </div>  {/* END Store Brief */}

            {/* Store Desc */}
            <div className="form-row">  
              <div className="col">
                <TextField
                  id="descshortInput"
                  value={descshort}
                  label="Short Description"
                  // placeholder="Enter description."
                  multiline
                  rowsMax={10}
                  fullWidth
                  margin="normal"
                  onChange={(e) => setdescshort(e.target.value)}
                  helperText="Shows in the Introduction. Include products sold, prices and any promotions.  (500 char. and 10 rows max)"
                  required
                />
              </div>
            </div>  {/* END Store Desc */}

            {/* Store Details */}
            <div className="form-row">  
              <div className="col">
                {/* <TextField
                  id="detailsInput"
                  value={details}
                  label="Details"
                  // placeholder="Enter details."
                  multiline
                  rowsMax={20}
                  fullWidth
                  margin="normal"
                  onChange={(e) => setdetails(e.target.value)}
                  helperText="Include product details, payment information details, discounts, delivery info and other text about your store.  (2000 char. and 20 rows max)"
                />
                 */}
                <Editor details={details} />
              </div>
            </div>  {/* END Store Details */}
            
            <Divider variant="middle" className="divider" />
            {/* Store Contact Nums */}
            <div className="form-row">  
              <div className="col">
                <label htmlFor="contactemailInput">Contact Email *</label>
                <input
                  onChange={(e) => setcontactemail(e.target.value)}
                  type="contactemail"
                  className="form-control form-control-sm"
                  id="contactemailInput"
                  pattern = "^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                  value={contactemail}
                  aria-describedby="emailHelp"
                  required
                />
                <div className="invalid-feedback">
                  Please enter a contact email in the correct format.
                </div>
              </div>

              <div className="col">
                <label htmlFor="primaryCellInput1">Contact number (1) * </label>
                <PhoneInput 
                  // onChange={(e) => setcontactnumber1(e.target.value)}
                  onChange = { setcontactnumber1 }
                  defaultCountry="PH"
                  className="form-control form-control-sm"
                  labels={labels}
                  id="primaryCellInput1"
                  required
                />
                <div className="invalid-feedback">
                  Enter your contact number in a valid format.
                </div>
              </div>
            </div>  {/* END Store Contact Nums */}


            {/* Store Contact Nums 2 */}
            <div className="form-row">  
              <div className="col">
                <label htmlFor="primaryCellInput2">Contact number (2) </label>
                <PhoneInput 
                  onChange = { setcontactnumber2 }
                  defaultCountry="PH"
                  className="form-control form-control-sm"
                  labels={labels}
                  id="primaryCellInput2"
                />
                <div className="invalid-feedback">
                  Enter your contact number in a valid format.
                </div>
              </div>

              <div className="col">
                <label htmlFor="primaryCellInput3">Contact number (3) </label>
                <PhoneInput 
                  onChange = { setcontactnumber3 }
                  defaultCountry="PH"
                  className="form-control form-control-sm"
                  labels={labels}
                  id="primaryCellInput3"
                />
                <div className="invalid-feedback">
                  Enter your contact number in a valid format.
                </div>
              </div>
            </div>  {/* END Store Contact Nums2 */}

            <Divider variant="middle" className="divider"/>
            {/* Social Media */}
            <div className="form-row">  
            <div className="col">
                <label htmlFor="facebooklinkInput"> Facebook Page </label>
                <input
                  onChange={(e) => setFacebook(e.target.value)}
                  pattern="[-a-zA-Z0-9@_.]{1,30}"
                  type="facebooklink"
                  className="form-control form-control-sm"
                  id="facebooklinkInput"
                />
                <div className="invalid-feedback">
                  Enter Facebook name
                </div>
              </div>

              <div className="col">
                <label htmlFor="twitterlinkInput"> Twitter ID </label>
                <input
                  onChange={(e) => setTwitter(e.target.value)}
                  pattern="[-a-zA-Z0-9@_.]{1,30}"
                  type="twitterlink"
                  className="form-control form-control-sm"
                  id="twitterlinkInput"
                />
                <div className="invalid-feedback">
                  Enter Valid Twitter ID.
                </div>
              </div>
              
            </div>  {/* END Social Media */}

            {/* Social Media 2*/}
            <div className="form-row">  
            <div className="col">
                <label htmlFor="instagramlinkInput"> Instagram ID </label>
                <input
                  onChange={(e) => setInstagram(e.target.value)}
                  pattern="[-a-zA-Z0-9@_.]{1,30}"
                  type="instagramlink"
                  className="form-control form-control-sm"
                  id="instagramlinkInput"
                />
                <div className="invalid-feedback">
                  Enter Valid Instagram ID.
                </div>
              </div>

              <div className="col">
                <label htmlFor="youtubelinkInput"> Youtube Link </label>
                <input
                  onChange={(e) => setYoutube(e.target.value)}
                  // pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)"
                  type="youtubelink"
                  className="form-control form-control-sm"
                  id="youtubelinkInput"
                />
                <div className="invalid-feedback">
                  Enter Valid URL.
                </div>
              </div>

              <div className="col">
                <label htmlFor="websiteInput"> Website Link </label>
                <input
                  onChange={(e) => setWebsite(e.target.value)}
                  pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)"
                  type="websitelink"
                  className="form-control form-control-sm"
                  id="websiteInput"
                />
                <div className="invalid-feedback">
                  Enter Valid URL.
                </div>
              </div>
            </div>  {/* END Social Media 2*/}

            <br/>  <Divider variant="middle" className="divider"/>

            {/* Category Chooser*/}
            <div className="form-row">  
                 <CategoryChooser initialCats={"ABC"} />  {/* Done so that InitialCats will be empty as real cats are numbers */}
            </div>  {/* END Category Chooser */}

            <br/>  <Divider variant="middle" className="divider"/>
            <Success className="divider"> Right click on map for approximate location of your store so users can easily find you geographically. </Success>
            {/* Map Chooser*/}
            <div className="form-row">  
                <MapChooser />
            </div>  {/* END Map Chooser */}

            <Divider variant="middle" className="divider"/>
            <Success className="divider"> Upload up to 10 pictures.  Limit image sizes to 10MB each. </Success>
            {/* Media Chooser*/}
            <div className="form-row">  
                <MediaChooser />
            </div>  {/* END Media Chooser */}

          <Divider variant="middle" className="divider"/>
          <div className="form-group form-check">
            <FormControlLabel
              control={<Checkbox onChange={(e) => setIsAgreeToTermsAndConditionsChecked(e.target.checked)} id="agreeToTermsAndConditionsCheckbox" />}
            />
             I agree to the {' '}
            <Link href="#" onClick={() => showTerms(true)}>
               Terms and Conditions
            </Link>
            { terms ? <Terms/> : ""}
          </div>
 
          {/* <div className="d-flex justify-content-center">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LcOt_wUAAAAAAsf72Ahs4-0flEsyTf_Pck2Dt7f"
              onChange={onChange}
            />  
          </div> */}
          <br/>

          <button type="submit" className="btn btn-primary btn-block btn-sm">Save Changes</button>
          <Success>
             NOTE: To publish you have to click on the link in the validation email.  You will receive an email everytime you save changes. 
          </Success>
          <Success>
             Only validate when you are ready for the world to see your changes:-)
          </Success>

          <div className="d-flex justify-content-between mt-4">
            <Link to="/managestores">
              <button type="button" className="btn btn-link btn-sm p-0">&#8592; Manage Store(s) </button>
            </Link>
            <Link to="/">
              <button type="button" className="btn btn-link btn-sm p-0"> Home </button>
            </Link>
          </div>

          </div>
        </form>
      </Card>
    </GridItem>
  </GridContainer>
  </div>
  );
};

CreateStore.propTypes = {
  backgroundColor: PropTypes.string,
  isFlat: PropTypes.bool,
  heading: PropTypes.string,
  headingColor: PropTypes.string,
  subheading: PropTypes.string,
  subheadingColor: PropTypes.string,
  image: PropTypes.node,
};

CreateStore.defaultProps = {
  backgroundColor: 'white',
  isFlat: false,
  heading: '',
  headingColor: 'black',
  subheading: '',
  subheadingColor: 'black',
  image: undefined,
};

export default CreateStore;
