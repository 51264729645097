import React, {useState, useEffect} from 'react';
// import ReactDOM from 'react-dom';
import { EditorState, RichUtils,  convertFromRaw, convertToRaw, Modifier} from 'draft-js';
import Editor from "draft-js-plugins-editor";

import 'draft-js/dist/Draft.css';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import addLink from '_helpers/addLinkPlugin';

// SOURCES:
//          https://codepen.io/Kiwka/pen/YNYvyG?editors=0010
//      **  https://dev.to/rose/rich-text-editing-on-the-web-getting-started-with-draft-js-2f68
//          https://draftjs.org/docs/getting-started
//      **  https://reactrocket.com/post/draft-js-persisting-content/
//      **  https://medium.com/@siobhanpmahoney/building-a-rich-text-editor-with-react-and-draft-js-part-2-2-embedding-links-d71b57d187a7
//
// IMPT NOTE:  DraftJS data has to be stored in DraftJS JSON format.  Cannot be plain text

const divStyle = {
  margin: "15px 5px 5px 5px",
  border: "2px blue",
}

const buttonStyle = {
  margin: "5px 5px",
}

const subtitleStyle = {
  color: "grey",
  margin: "5px 5px 15px 5px",
}


function MyEditor(props) {
  let content = props.details

  const [editorState, setEditorState] = useState(() => EditorState.createWithContent(convertFromRaw(JSON.parse(content))), );
  const [plugins, setPlugins] = useState([ addLink, ]);


  useEffect(() => {
    const localcontent = editorState.getCurrentContent()
    window.localStorage.setItem('content', JSON.stringify(convertToRaw(localcontent)));
  }, [editorState])

  function handleKeyCommand(command) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return true;
    }
    return false;
  }

 
  return( 
    <>
        <div style={divStyle}>
          <Paper elevation={3}>
            <Typography variant="subtitle2" gutterBottom style={divStyle}>
                Details
            </Typography>
            <Divider variant="middle"/>
            <Editor 
                editorState={editorState} 
                onChange={setEditorState} 
                handleKeyCommand={handleKeyCommand}
                plugins={plugins}
            />
          </Paper>
            <Button style={buttonStyle} onClick={onBoldClick} variant="outlined" color="primary"> Bold </Button>
            <Button style={buttonStyle} onClick={onUnderlineClick} variant="outlined" color="primary"> Underline </Button>
            <Button style={buttonStyle} onClick={onItalicClick} variant="outlined" color="primary"> Italic </Button>
            <Button style={buttonStyle} onClick={onH1Click} variant="outlined" color="secondary"> H1 </Button>
            <Button style={buttonStyle} onClick={onH2Click} variant="outlined" color="secondary"> H2 </Button>
            <Button style={buttonStyle} onClick={onH3Click} variant="outlined" color="secondary"> H3 </Button>
            <Button style={buttonStyle} onClick={onH4Click} variant="outlined" color="secondary"> H4 </Button>
            <Button style={buttonStyle} onClick={onAddLink} variant="outlined" color="secondary"> Add Link </Button>
           <Typography variant="caption" display="block" gutterBottom style={subtitleStyle}>
                Include product details, payment information details, discounts, delivery info and other text about your store.  This section has Rich Text formating.
          </Typography>
          <br/> 
        </div>
    </>
   )

  // FORMATING FUNCTIONS
  function onBoldClick(e) {
    e.preventDefault()
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'))
  }

  function onUnderlineClick(e) {
    e.preventDefault()
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'))
  }

  function onItalicClick(e) {
    e.preventDefault()
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'))
  }

  function onH1Click(e) {
    e.preventDefault()
    setEditorState(RichUtils.toggleBlockType(editorState, 'header-three'))
  }

  function onH2Click(e) {
    e.preventDefault()
    setEditorState(RichUtils.toggleBlockType(editorState, 'header-four'))
  }

  function onH3Click(e) {
    e.preventDefault()
    setEditorState(RichUtils.toggleBlockType(editorState, 'header-five'))
  }

  function onH4Click(e) {
    e.preventDefault()
    setEditorState(RichUtils.toggleBlockType(editorState, 'header-six'))
  }

  function onAddLink(e) {
    // e.preventDefault();
    console.log("ADDLINK");
    const link = window.prompt("Please paste full URL link: ")
    const linkselection = editorState.getSelection();
    console.log("LINK: " + link);
    console.log("LINK SEL: " + linkselection);
    if (!link) {
      setEditorState(RichUtils.toggleLink(editorState, linkselection, null));
      return "handled";
    }

    const content = editorState.getCurrentContent();
    const contentStateWithEntity = content.createEntity("LINK", "MUTABLE", {
      url: link
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const contentStateWithLink = Modifier.applyEntity(
      contentStateWithEntity,
      linkselection,
      entityKey,
    );
    console.log("CONTENT: " + contentStateWithLink);
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithLink,
    });

    setEditorState(RichUtils.toggleLink(newEditorState, linkselection, entityKey));
    return "handled";

  }
      
}

export default MyEditor;