import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { useAuth, currentUserRole } from "_components";
import { apiUrl, jwAToken } from "config.js";
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';

// UI
import "assets/css/LoginForm.css";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TransitionAlert from "components/Alerts/TransitionAlert.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
// import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const SocialMedia = ( {greeting} ) => {
  const { setAuthTokens } = useAuth();
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isDone, setDone] = useState(false);
  const [role, setRole] = useState(null);
  const [postData, setPostdata] = useState({});
  const fbgreeting = `${greeting}` + " with Facebook"
  const axiosHeader = {
    headers: { Authorization: `Bearer ${jwAToken}` }
  };
  const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }));
  const classes = useStyles();
  

  // ///////////////////////////////////////////////////////////////////
  // SECTION to handle ROLE SETTING
  const handleClose = () => {
    setOpen(false);
  };

  // ///////////////////////////////////////////////////////////////////
  // SECTION to handle API POST call after Google and FB calls
  function postLogin(role) {
    setOpen(true)
    console.log("SocialMedia Signup: " + role)
    axios.post(
      `${apiUrl}/rpc/vmsignup_social`, 
      {   "email": postData.email,
          "fname": postData.firstName,
          "lname": postData.lastName,
          "vmrole": role,
          "provider": postData.provider,
          "provider_id": postData.provider_id,
          "provider_image": postData.provider_img,
          "provider_accesstoken": postData.provider_token
      },
        axiosHeader      
      ).then(result => {
          if (result.status === 200) {
            setAuthTokens(result.data);
            setIsError(false);
            sessionStorage.setItem("tokens", JSON.stringify(result.data));
            setDone(true); 
          } else {
            alert("Creating Account. Not 200 Response. ")
            setDone(true); 
          }
    })
    .catch(e => {
      alert("Account Exists.  Please log in.")
      setDone(true); 
      // INSTEAD OF showing error, redirect user to create account.  setIsError(true);
    })
    ;
  }  // END postLogin
 
  if (isDone) {
    return <Redirect to="/login-page"/>;
  }

  if (isError) {
    return (
        <>
          <TransitionAlert severity="error" message="Email support@villagemarket.ph.  With subject of 'Social Create Account Issue'"/>
        </>
    )
  }

  const responseFacebook = (res) => {
    console.log("FB Response: " + JSON.stringify(res))
    setOpen(true)
    if (res.name) {
        setPostdata({
          firstName: res.name,
          lastName: res.name,
          provider: "Facebook",
          email: res.email,
          provider_id: res.id,
          provider_token: res.accessToken,
          provider_img: res.picture.data.url
        })
    } else {
        setDone(true)
    }
  }

  const responseGoogle = (res) => {
    console.log("G Response: " + JSON.stringify(res))
    setOpen(true)
    if (res.profileObj) {
      setPostdata({
        firstName: res.profileObj.givenName,
        lastName: res.profileObj.familyName,
        provider: "Google",
        email: res.profileObj.email,
        provider_id: res.googleId,
        provider_token: res.accessToken,
        provider_img: res.profileObj.imageUrl
      })
      // alert("GG Response: " + JSON.stringify(postData) );
    
    } else {
      setDone(true)
    }
  }

  const responseGoogleFail = (res) => {
    console.log("G Login failed.")
  }

  const componentClicked = () => {
    console.log("Component Clicked")
  }

 return(  
    <div className="loginbutton">
      <div> 
        <FacebookLogin
            appId="753810018541223"
            autoLoad
            fields="name,email,picture"
            onClick={componentClicked}
            callback={responseFacebook}
            cssClass="btnFacebook"
            icon="fa-facebook"
            textButton = {fbgreeting}   
        />

        <GoogleLogin
            clientId={'722516736932-hdjcnb95f935kl5eb1puovvset37i928.apps.googleusercontent.com'}
            onSuccess={responseGoogle}
            onFailure={responseGoogleFail}
            cookiePolicy={'single_host_origin'}
            // isSignedIn={true}
            className="btnGoogle"
            buttonText="Signup with Google"
        />

        {
        open 
        ? <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose}>
          <DialogTitle>Choose user role</DialogTitle>
          <DialogContent>
            <form className={classes.container}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="demo-dialog-native">Roles</InputLabel>
                <Select
                  native
                  value={role}
                  onChange={(e) => postLogin(e.target.value)   }
                  type="text"
                  input={<Input id="demo-dialog-native" />}
                >
                  <option aria-label="None" value="" />
                  <option value="Buyer">  Buyer  </option>
                  <option value="Seller"> Seller </option>
                </Select>
              </FormControl>
            </form>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} color="primary">
              User saved. Login after. 
            </Button>
          </DialogActions>
        </Dialog>   
        : ""
        } 
      </div>
    </div>
  );
};

export default SocialMedia;