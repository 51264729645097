import React from 'react';
import Text from "./Text.js";
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footer/Footer.js";


import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import Paper from '@material-ui/core/Paper';


import styles from "assets/jss/material-kit-react/views/sitesPages.js";
const useStyles = makeStyles(styles);

export default function Terms() {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.navbar}>
        <ScrollTransparentNavbar />
      </div>
      <div className={classes.mainRaised} >
            <Typography variant="h6" className={classes.title}>
                TERMS OF USE
            </Typography>
            <div className={classes.text}>
                <Text />
            </div>
      </div>
      <Footer />
    </div>
  );
}
