import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { useAuth, currentUserRole } from "_components";
import { apiUrl, jwAToken } from "config.js";
import SocialMedia from "./SocialMedia.js";


// UI
// import faStyles from 'font-awesome/css/font-awesome.css'
import "assets/css/LoginForm.css";
import "bootstrap/dist/css/bootstrap.css";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TransitionAlert from "components/Alerts/TransitionAlert.js";

const LoginForm = ({
  image, heading, subheading, backgroundColor, isFlat, headingColor, subheadingColor,
}) => {

  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [username, setusername] = useState("");
  const [password, setPassword] = useState("");
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { setAuthTokens } = useAuth();
  // const referer = props.location.state.referer || '/';

  const axiosHeader = {
    headers: { Authorization: `Bearer ${jwAToken}` }
  };

  function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitted(true);
    const isFormValid = e.target.checkValidity();
    if (isFormValid) {
      // Success
      // alert(`Signing in with email: ${username} and password: ${password}, and the user wants to be remembered: ${isRememberMeChecked}`);
    } else {
      // Form has errors, so don't sign in
      alert(`Signing in with either WRONG email: ${username} and/or password: ${password}`);
    }
  }

  function postLogin() {
    axios.post(
        `${apiUrl}/rpc/vmlogin2`, 
        {
          "email": username,
          "pass": password
        },
        axiosHeader      
      ).then(result => {
      if (result.status === 200) {
        setAuthTokens(result.data);
        setLoggedIn(true);
        setIsError(false);
        localStorage.setItem("faves", JSON.stringify(result.data[0].ids))
        (isRememberMeChecked) && localStorage.setItem("tokens", JSON.stringify(result.data));
        (isLoggedIn) && sessionStorage.setItem("tokens", JSON.stringify(result.data));
      } else {
        setIsError(true);
      }
    })
    .catch(e => {
      setIsError(true);
    })
    ;
  }  // END postLogin

  if (isLoggedIn) {
    if (currentUserRole() === "vm_seller_role") { 
      return <Redirect to="/managestores"/>;
    }
    else {
      return <Redirect to="/"/>;
    }
  }

  if (isError) {
     return (<>
              <TransitionAlert severity="error" message="Wrong email or password entered.  Please try again." />
            </>)
  }

 return (
    <div style={{ backgroundColor }} className="col d-flex justify-content-center align-items-center flex-column">
      <div className="login-form">
        {image && (
          <div className="d-flex justify-content-center">
            <img src={image} style={{ width: '100px' }} alt="logo" />
          </div>
        )}
        <div className="text-center">
          {heading && (
            <h3 style={{ color: headingColor, marginTop: '40px' }}>{heading}</h3>
          )}
          {subheading && (
            <p style={{ color: subheadingColor }}>
              {subheading}
            </p>
          )}
        </div>
        
        <form className={`p-4 ${isFlat ? '' : 'shadow-sm rounded-sm bg-white'} ${isSubmitted ? 'was-validated' : ''}`} noValidate onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="usernameInput">Email address</label>
            <input
              onChange={(e) => setusername(e.target.value)}
              type="username"
              className="form-control form-control-sm"
              id="usernameInput"
              aria-describedby="emailHelp"
              required
            />
            <div className="invalid-feedback">
              Please enter an email address in the correct format.
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="passwordInput">Password</label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="form-control form-control-sm"
              id="passwordInput"
              required
            />

          </div>
          <div className="form-group form-check d-flex align-items-center">
            <FormControlLabel
              control={<Checkbox onChange={(e) => setIsRememberMeChecked(e.target.checked)} id="rememberMeCheck" />}
              label=" Remember me. "
            />
          </div>

          <button  onClick={postLogin} type="submit" className="btn btn-primary btn-block btn-sm">Log In</button>
          <br/>

          <SocialMedia greeting="Log In"/>

          <div className="d-flex justify-content-between mt-4">
            <Link to="/createacct">
              <button type="button" className="btn btn-link btn-sm p-0">Create new account</button>
            </Link>
            <Link to="/forgotpass">
              <button type="button" className="btn btn-link btn-sm p-0">Forgot or Reset password</button>
              </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  backgroundColor: PropTypes.string,
  isFlat: PropTypes.bool,
  heading: PropTypes.string,
  headingColor: PropTypes.string,
  subheading: PropTypes.string,
  subheadingColor: PropTypes.string,
  image: PropTypes.node,
};

LoginForm.defaultProps = {
  backgroundColor: 'white',
  isFlat: false,
  heading: '',
  headingColor: 'black',
  subheading: '',
  subheadingColor: 'black',
  image: undefined,
};

export default LoginForm;
